import "../style/Dashboard.scss";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { useAuth } from "../context/AuthContext";
import CharacterCards from './CharacterCards';

// Regular UI images
import token from "../img/token.png";
import emblemplus from "../img/emblemplus.png";
import emblem from "../img/emblem.png";
import freeuser from "../img/freeuser.webp";
import goldImg from "../img/gold.png";
import xpImg from "../img/xp.png";
import tpImg from "../img/tp.png";
import ssImg from "../img/ss.png";
import clanImg from "../img/clan.png";
import crewImg from "../img/crew.png";
import prestigeImg from "../img/prestige.png";
import rollsImg from "../img/rolls.png";
import repImg from "../img/rep.png";
import meritImg from "../img/merit.png";
import bossKillImg from "../img/bosskill.png";
import damageImg from "../img/crewdamage.png";
import arenaEnergyImg from "../img/arenaenergy.png";
import arenaTrophiesImg from "../img/arenatrophies.png";
import arenaImg from "../img/arena.png";
import crewRollsImg from "../img/crewrolls.png";
import crewVitalityImg from "../img/crewvitality.png";

// Character Class Images
import skill2000 from "../img/Character/Class/skill_2000.png";
import skill2001 from "../img/Character/Class/skill_2001.png";
import skill2002 from "../img/Character/Class/skill_2002.png";
import skill2003 from "../img/Character/Class/skill_2003.png";
import skill2004 from "../img/Character/Class/skill_2004.png";

// Element Images
import element1 from "../img/Character/Element/1.png";
import element2 from "../img/Character/Element/2.png";
import element3 from "../img/Character/Element/3.png";
import element4 from "../img/Character/Element/4.png";
import element5 from "../img/Character/Element/5.png";

// Rank Images
import rank1 from "../img/Character/Rank/1.png";
import rank2 from "../img/Character/Rank/2.png";
import rank3 from "../img/Character/Rank/3.png";
import rank4 from "../img/Character/Rank/4.png";
import rank5 from "../img/Character/Rank/5.png";
import rank6 from "../img/Character/Rank/6.png";
import rank7 from "../img/Character/Rank/7.png";
import rank8 from "../img/Character/Rank/8.png";
import rank9 from "../img/Character/Rank/9.png";

// Village/Arena Images
import village1 from "../img/Character/Arena/1.png";
import village2 from "../img/Character/Arena/2.png";
import village3 from "../img/Character/Arena/3.png";
import village4 from "../img/Character/Arena/4.png";
import village5 from "../img/Character/Arena/5.png";

// Talent Images
import bonesImg from "../img/Character/Talent/bones.png";
import cataclysmImg from "../img/Character/Talent/cataclysm.png";
import crystalImg from "../img/Character/Talent/crystal.png";
import deImg from "../img/Character/Talent/de.png";
import demonImg from "../img/Character/Talent/demon.png";
import dpImg from "../img/Character/Talent/dp.png";
import eighttextImg from "../img/Character/Talent/eightext.png";
import eoaImg from "../img/Character/Talent/eoa.png";
import eomImg from "../img/Character/Talent/eom.png";
import forestImg from "../img/Character/Talent/forest.png";
import insectImg from "../img/Character/Talent/insect.png";
import lavaImg from "../img/Character/Talent/lava.png";
import orochiImg from "../img/Character/Talent/orochi.png";
import saintImg from "../img/Character/Talent/saint.png";
import silhouetteImg from "../img/Character/Talent/silhouette.png";
import vampiricImg from "../img/Character/Talent/vampiric.png";

import Payment from './Payment';
import ChangeUsernamePopup from './ChangeUsernamePopup';

// Image mappings
const classImages = {
    'skill_2000': skill2000,
    'skill_2001': skill2001,
    'skill_2002': skill2002,
    'skill_2003': skill2003,
    'skill_2004': skill2004
};

const elementImages = {
    1: element1,
    2: element2,
    3: element3,
    4: element4,
    5: element5
};

const rankImages = {
    1: rank1,
    2: rank2,
    3: rank3,
    4: rank4,
    5: rank5,
    6: rank6,
    7: rank7,
    8: rank8,
    9: rank9
};

const villageImages = {
    1: village1,
    2: village2,
    3: village3,
    4: village4,
    5: village5
};

const talentImages = {
    'bones': bonesImg,
    'cataclysm': cataclysmImg,
    'crystal': crystalImg,
    'de': deImg,
    'demon': demonImg,
    'dp': dpImg,
    'eightext': eighttextImg,
    'eoa': eoaImg,
    'eom': eomImg,
    'forest': forestImg,
    'insect': insectImg,
    'lava': lavaImg,
    'orochi': orochiImg,
    'saint': saintImg,
    'silhouette': silhouetteImg,
    'vampiric': vampiricImg
};

function Dashboard() {
    const navigate = useNavigate();
    const { login, logout } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [sessionValid, setSessionValid] = useState(false);
    const [userData, setUserData] = useState({
        username: localStorage.getItem("username"),
        accountId: localStorage.getItem("acc_id"),
        tokens: localStorage.getItem("tokens"),
        accountType: localStorage.getItem("acc_type"),
        characters: JSON.parse(localStorage.getItem("characters") || "[]")
    });

    const handleLogoutClick = () => {
        localStorage.clear();
        logout();
        navigate("/", { replace: true });
    };

    const handleInvalidSession = useCallback(() => {
        setSessionValid(false);
        localStorage.clear();
        logout();
        navigate("/", { replace: true });
    }, [logout, navigate]);

    const verifySession = useCallback(async () => {
        const acc_id = localStorage.getItem("acc_id");
        const web_session_key = localStorage.getItem("web_session_key");
        
        if (!acc_id || !web_session_key) {
            handleInvalidSession();
            return false;
        }

        try {
            const response = await $.ajax({
                type: "POST",
                url: "https://ninjarift.org/account/VerifyAccount.php",
                data: {
                    acc_id: acc_id,
                    web_session_key: web_session_key
                }
            });

            if (response.valid) {
                const accType = response.acc_type === 2 ? "Emblem Plus User" : 
                               response.acc_type === 1 ? "Premium User" : "Free User";
                
                localStorage.setItem("username", response.username);
                localStorage.setItem("tokens", response.tokens);
                localStorage.setItem("acc_type", accType);
                localStorage.setItem("characters", JSON.stringify(response.characters || []));
                
                setUserData({
                    username: response.username,
                    accountId: response.acc_id,
                    tokens: response.tokens,
                    accountType: accType,
                    characters: response.characters || []
                });
                setSessionValid(true);
                login();
                return true;
            } else {
                handleInvalidSession();
                return false;
            }
        } catch (error) {
            handleInvalidSession();
            return false;
        } finally {
            setIsLoading(false);
        }
    }, [handleInvalidSession, login]);

    useEffect(() => {
        let mounted = true;

        const initializeSession = async () => {
            if (mounted) {
                await verifySession();
            }
        };

        initializeSession();

        const intervalId = setInterval(() => {
            if (mounted) {
                verifySession();
            }
        }, 5 * 60 * 1000);

        return () => {
            mounted = false;
            clearInterval(intervalId);
        };
    }, [verifySession]);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setUserData(prev => ({
            ...prev,
            username: localStorage.getItem("username")
        }));
    };

    if (isLoading) {
        return (
            <div className="content-container">
                <div>Verifying session...</div>
            </div>
        );
    }

    if (!sessionValid) {
        return null;
    }

    const getAccountTypeImage = (accountType) => {
        switch(accountType) {
            case "Emblem Plus User":
                return <img src={emblemplus} alt="Emblem Plus User" />;
            case "Premium User":
                return <img src={emblem} alt="Premium User" />;
            case "Free User":
                return <img src={freeuser} alt="Free User" />;
            default:
                return null;
        }
    };

    return (
        <div className="content-container">
            <div className="h1-dashboard">
                <span className="title">Dashboard</span>
                <button className="logout-btn" onClick={handleLogoutClick}>Logout</button>
            </div>
            
            <div className="data-container">
                <div className="data-item">
                    <label>Account Username:</label>
                    <span>{userData.username}</span>
                    <button className="change-username-btn" onClick={openPopup}>Change Username</button>
                </div>
                <div className="data-item">
                    <label>Account ID:</label>
                    <span>{userData.accountId}</span>
                </div>
                <div className="data-item">
                    <label>Account Type:</label>
                    <span>{userData.accountType}</span>
                    {getAccountTypeImage(userData.accountType)}
                </div>
                <div className="data-item">
                    <label>Tokens:</label>
                    <span>{userData.tokens}</span>
                    <img src={token} alt="Token" />
                </div>
            </div>
            
            <div className="data-container">
                <CharacterCards 
                    characters={userData.characters} 
                    classImages={classImages}
                    elementImages={elementImages}
                    rankImages={rankImages}
                    villageImages={villageImages}
                    talentImages={talentImages}
                    xpImg={xpImg}
                    goldImg={goldImg}
                    tpImg={tpImg}
                    ssImg={ssImg}
                    clanImg={clanImg}
                    crewImg={crewImg}
                    prestigeImg={prestigeImg}
                    rollsImg={rollsImg}
                    repImg={repImg}
                    meritImg={meritImg}
                    bossKillImg={bossKillImg}
                    damageImg={damageImg}
                    arenaEnergyImg={arenaEnergyImg}
                    arenaTrophiesImg={arenaTrophiesImg}
                    arenaImg={arenaImg}
                    crewRollsImg={crewRollsImg}
                    crewVitalityImg={crewVitalityImg}
                />
            </div>

            <Payment 
                username={userData.username}
                accountId={userData.accountId}
                currentTokens={userData.tokens}
                accountType={userData.accountType}
                characters={userData.characters}
            />

            {isPopupOpen && (
                <ChangeUsernamePopup onClose={closePopup} />
            )}
        </div>
    );
}

export default Dashboard;

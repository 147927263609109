import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../style/VillageMembers.scss";

// Import league rank images
import rank1 from "../img/Leagues/1.png";
import rank2 from "../img/Leagues/2.png";
import rank3 from "../img/Leagues/3.png";
import rank4 from "../img/Leagues/4.png";
import rank5 from "../img/Leagues/5.png";
import rank6 from "../img/Leagues/6.png";
import rank7 from "../img/Leagues/7.png";
import rank8 from "../img/Leagues/8.png";

function VillageMembers({ villageName, villageId, onBackClick }) {
  const [membersData, setMembersData] = useState([]);
  const intervalRef = useRef(null);

  const rankImages = {
    1: rank1,
    2: rank2,
    3: rank3,
    4: rank4,
    5: rank5,
    6: rank6,
    7: rank7,
    8: rank8
  };

  const getTrophyRank = (trophies) => {
    if (trophies >= 10000) return 8;
    if (trophies >= 7500) return 7;
    if (trophies >= 5000) return 6;
    if (trophies >= 3000) return 5;
    if (trophies >= 2000) return 4;
    if (trophies >= 1000) return 3;
    if (trophies >= 500) return 2;
    return 1;
  };

  useEffect(() => {
    const fetchMembersData = async () => {
      if (!villageId) {
        console.error("Invalid villageId:", villageId);
        return;
      }

      try {
        const response = await axios.get(
          `api/detail_village_website.php?village_id=${villageId}`
        );

        if (response.data && response.data.members) {
          const members = response.data.members;

          const updatedMembers = members.map((member, index) => {
            const gapFromRank1 = members[0].trophies - member.trophies;
            const prevMember = members[index - 1];
            const gapFromAbove = prevMember
              ? prevMember.trophies - member.trophies
              : null;

            return {
              ...member,
              gapFromRank1,
              gapFromAbove,
              leagueRank: getTrophyRank(member.trophies)
            };
          });

          setMembersData(updatedMembers);
        }
      } catch (error) {
        console.error("Error fetching village members:", error);
      }
    };

    fetchMembersData();

    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchMembersData, 2000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [villageId]);

  return (
    <div className="village-members">
      <h2 className="village-members-header">{villageName} Members</h2>
      <button className="back-button" onClick={onBackClick}>
        Back to Main Ranking
      </button>

      <div className="table-wrapper">
        <div className="table-scroll">
          <table className="member-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>League</th>
                <th>Character Name</th>
                <th>Level</th>
                <th>Trophies</th>
                <th>Gap from Rank 1</th>
                <th>Gap</th>
              </tr>
            </thead>
            <tbody>
              {membersData.slice(0, 100).map((member, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "row-even" : "row-odd"}
                >
                  <td>{index + 1}</td>
                  <td>
                    <img 
                      src={rankImages[member.leagueRank]}
                      alt={`League ${member.leagueRank}`}
                      style={{ width: '60px', height: '60px' }}
                    />
                  </td>
                  <td>{member.character_name}</td>
                  <td>{member.character_level}</td>
                  <td>{member.trophies}</td>
                  <td className="gap-from-rank-1-column">{member.gapFromRank1}</td>
                  <td className="gap-column">
                    {member.gapFromAbove !== null ? member.gapFromAbove : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default VillageMembers;

// components/Wiki/Category.jsx
import React, { useState, useEffect } from 'react';
import { Scroll, ArrowLeft } from 'lucide-react';
import { Card, CardContent } from '../ui/card';
import Subcategories from './Subcategories';
import Endpoint from './Endpoint';

const Category = ({ categoryId, onBack, initialSelectedSubcategory = null }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [error, setError] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(initialSelectedSubcategory);
  const [navigationStack, setNavigationStack] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch category details
        const categoryResponse = await fetch('https://playninjarift.com/server/admin/get_categories.php');
        const categoryData = await categoryResponse.json();

        if (categoryData.status === 'success') {
          const currentCategory = categoryData.data.find(cat => cat.category_id === categoryId);
          if (currentCategory) {
            setCategory({
              id: currentCategory.category_id,
              name: currentCategory.name,
              description: currentCategory.description,
              icon: currentCategory.icon_url ? (
                <img
                  src={currentCategory.icon_url}
                  alt={currentCategory.name}
                  className="w-20 h-20 object-contain"
                />
              ) : (
                <Scroll className="w-20 h-20" />
              )
            });

            if (!selectedSubCategory || !selectedSubCategory.is_endpoint) {
              // Fetch subcategories only if not viewing an endpoint
              const endpoint = selectedSubCategory
                ? `https://playninjarift.com/server/admin/get_subcategories.php?category_id=${categoryId}&parent_id=${selectedSubCategory.subcategory_id}`
                : `https://playninjarift.com/server/admin/get_subcategories.php?category_id=${categoryId}`;

              const response = await fetch(endpoint);
              const data = await response.json();

              if (data.status === 'success') {
                setSubCategories(data.data || []);
                setError(null);
              } else {
                throw new Error(data.message || 'Failed to fetch subcategories');
              }
            }
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load content. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [categoryId, selectedSubCategory]);

  const handleSubCategoryClick = async (subcategory) => {
    // If it's an endpoint, just select it
    if (subcategory.is_endpoint) {
      setSelectedSubCategory(subcategory);
      setNavigationStack(prev => [...prev, {
        subcategory: selectedSubCategory,
        subcategories: subCategories
      }]);
      return;
    }

    // Otherwise, fetch subcategories
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://playninjarift.com/server/admin/get_subcategories.php?category_id=${categoryId}&parent_id=${subcategory.subcategory_id}`
      );
      const data = await response.json();

      if (data.status === 'success') {
        setNavigationStack(prev => [...prev, {
          subcategory: selectedSubCategory,
          subcategories: subCategories
        }]);

        setSelectedSubCategory(subcategory);
        setSubCategories(data.data || []);
      }
    } catch (err) {
      console.error('Error fetching subcategories:', err);
      setError('Failed to load subcategories');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (navigationStack.length > 0) {
      // Pop the last state from the stack
      const previousState = navigationStack[navigationStack.length - 1];
      setSelectedSubCategory(previousState.subcategory);
      setSubCategories(previousState.subcategories);
      setNavigationStack(prev => prev.slice(0, -1));
    } else {
      // If no previous state, go back to categories
      onBack();
    }
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="category-page">
      <button
        onClick={handleBack}
        className="back-button mb-4 text-blue-400 hover:text-blue-300 transition-colors flex items-center gap-2"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to {navigationStack.length > 0 ?
          (navigationStack[navigationStack.length - 1]?.subcategory?.name || 'Previous Level') :
          'Categories'}
      </button>

      <div className="category-header mb-6 flex items-center gap-4">
        {/* Change this condition to show either category or subcategory icon */}
        <div className="category-icon">
          {selectedSubCategory ? (
            selectedSubCategory.icon_url ? (
              <img
                src={selectedSubCategory.icon_url}
                alt={selectedSubCategory.name}
                className="w-20 h-20 object-contain"
              />
            ) : (
              <Scroll className="w-20 h-20" />
            )
          ) : category?.icon ? (
            category.icon
          ) : (
            <Scroll className="w-20 h-20" />
          )}
        </div>
        <div>
          <h2 className="text-2xl font-bold text-white mb-2">
            {selectedSubCategory ? selectedSubCategory.name : category?.name}
          </h2>
          <p className="text-gray-400">
            {selectedSubCategory ? selectedSubCategory.description : category?.description}
          </p>
        </div>
      </div>

      <Card className="category-card bg-[rgba(255,255,255,0.03)] border-[rgba(255,255,255,0.1)]">
        <CardContent>
          {selectedSubCategory?.is_endpoint ? (
            <Endpoint subcategoryId={selectedSubCategory.subcategory_id} />
          ) : (
            <Subcategories
              subcategories={subCategories}
              onSubCategoryClick={handleSubCategoryClick}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Category;
import React, { useState } from 'react';
import './ServerSettings.scss';

const CrewSeasonManager = ({
  crewSeasonInfo,
  crewRewards,
  isLoading,
  onEndSeason,
  onSaveResults,
  onStartNewSeason,
  onPhase1RewardChange,
  onPhase2RewardChange,
  formatDateTime
}) => {
  const [error, setError] = useState(null);
  const [actionInProgress, setActionInProgress] = useState(false);

  const handleEndSeason = async () => {
    try {
      setError(null);
      setActionInProgress(true);
      await onEndSeason();
    } catch (err) {
      setError(err.message || 'Failed to end season. Please try again.');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleSaveResults = async () => {
    try {
      setError(null);
      setActionInProgress(true);
      await onSaveResults();
    } catch (err) {
      setError(err.message || 'Failed to save results. Please try again.');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleStartNewSeason = async () => {
    try {
      setError(null);
      setActionInProgress(true);
      await onStartNewSeason();
    } catch (err) {
      setError(err.message || 'Failed to start new season. Please try again.');
    } finally {
      setActionInProgress(false);
    }
  };

  return (
    <div className="settings-section">
      <h3>Crew Season Management</h3>
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      <div className="content-wrapper">
        <div className="season-info">
          <p>
            <span>Current Season</span>
            <strong>{crewSeasonInfo.currentSeason}</strong>
          </p>
          <p>
            <span>Status</span>
            <span className={`status ${crewSeasonInfo.isActive ? 'active' : 'ended'}`}>
              {crewSeasonInfo.isActive ? 'Active' : 'Season Ended'}
            </span>
          </p>
          {crewSeasonInfo.isActive && (
            <p>
              <span>Current Phase</span>
              <span className={`status phase-${crewSeasonInfo.phase}`}>
                Phase {crewSeasonInfo.phase}
              </span>
            </p>
          )}
          {!crewSeasonInfo.isActive && crewSeasonInfo.endDate && (
            <p>
              <span>Last Season End</span>
              <strong>{formatDateTime(crewSeasonInfo.endDate)}</strong>
            </p>
          )}
          {!crewSeasonInfo.isActive && (
            <p>
              <span>Results Status</span>
              <span className={`status ${crewSeasonInfo.resultsSaved ? 'saved' : 'not-saved'}`}>
                {crewSeasonInfo.resultsSaved ? 'Results Saved' : 'Results Not Saved'}
              </span>
            </p>
          )}
          {crewSeasonInfo.isActive && crewSeasonInfo.endDate && (
            <>
              <p>
                <span>Season Ends</span>
                <strong>{formatDateTime(crewSeasonInfo.endDate)}</strong>
              </p>
              {crewSeasonInfo.phase === 2 && crewSeasonInfo.phase2EndDate && (
                <p>
                  <span>Phase 2 Ends</span>
                  <strong className="phase-end-date">
                    {formatDateTime(crewSeasonInfo.phase2EndDate)}
                  </strong>
                </p>
              )}
            </>
          )}
        </div>

        <div className="season-controls">
          {crewSeasonInfo.isActive ? (
            <button
              className="end-season-btn"
              onClick={handleEndSeason}
              disabled={isLoading || actionInProgress}
            >
              {actionInProgress ? 'Ending Season...' : 'End Current Season'}
            </button>
          ) : (
            <>
              {!crewSeasonInfo.resultsSaved && (
                <button
                  className="save-results-btn"
                  onClick={handleSaveResults}
                  disabled={isLoading || actionInProgress}
                >
                  {actionInProgress ? 'Saving Results...' : 'Save Season Results'}
                </button>
              )}
              {crewSeasonInfo.resultsSaved && (
                <div className="new-season-form">
                  <div className="rewards-section">
                    <div className="phase1-rewards">
                      <label>Phase 1 Reward (repeated 7 times)</label>
                      <input
                        type="text"
                        value={crewRewards.phase1Reward}
                        onChange={(e) => onPhase1RewardChange(e.target.value)}
                        placeholder="Enter Phase 1 reward"
                        disabled={actionInProgress}
                        required
                      />
                    </div>
                    <div className="phase2-rewards">
                      <label>Phase 2 Rewards (7 different rewards)</label>
                      {crewRewards.phase2Rewards.map((reward, index) => (
                        <input
                          key={index}
                          type="text"
                          value={reward}
                          onChange={(e) => onPhase2RewardChange(index, e.target.value)}
                          placeholder={`Phase 2 reward ${index + 1}`}
                          disabled={actionInProgress}
                          required
                        />
                      ))}
                    </div>
                  </div>
                  <button
                    className="start-season-btn"
                    onClick={handleStartNewSeason}
                    disabled={
                      isLoading ||
                      actionInProgress ||
                      !crewRewards.phase1Reward ||
                      crewRewards.phase2Rewards.some(r => !r)
                    }
                  >
                    {actionInProgress ? 'Starting New Season...' : 'Start New Season'}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CrewSeasonManager;

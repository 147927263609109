import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment-timezone";
import "../style/ClanRanking.scss";
import ClanMembers from "./ClanMembers";

// Import rank icons
import championIcon from "../img/champion.png";
import firstRunnerUpIcon from "../img/firstrunnerup.png";
import secondRunnerUpIcon from "../img/secondrunnerup.png";

function ClanRanking() {
  const [clans, setClans] = useState([]);
  const [countdown, setCountdown] = useState(null);
  const [seasonNumber, setSeasonNumber] = useState(null);
  const [seasonEndTimestamp, setSeasonEndTimestamp] = useState(null);
  const [activeClanId, setActiveClanId] = useState(null);
  const [activeClanName, setActiveClanName] = useState("");
  const [activeClanMembers, setActiveClanMembers] = useState([]);
  const [viewingMainRanking, setViewingMainRanking] = useState(true);
  const intervalRef = useRef(null);

  const getRankIcon = (rank) => {
    switch(rank) {
      case 1: return <img src={championIcon} alt="Champion" className="rank-icon" />;
      case 2: return <img src={firstRunnerUpIcon} alt="First Runner Up" className="rank-icon" />;
      case 3: return <img src={secondRunnerUpIcon} alt="Second Runner Up" className="rank-icon" />;
      default: return null;
    }
  };

  useEffect(() => {
    const fetchSeasonData = async () => {
      try {
        const seasonResponse = await axios.get("/api/refresh_time_website.php");
        if (seasonResponse.data) {
          const { season, season_end } = seasonResponse.data;
          const seasonEndInCST = moment
            .tz(season_end, "America/Chicago")
            .valueOf();
          setSeasonNumber(season);
          setSeasonEndTimestamp(seasonEndInCST);
        }
      } catch (error) {
        console.error("Error fetching season data:", error);
      }
    };

    fetchSeasonData();
  }, []);

  useEffect(() => {
    const fetchClanData = async () => {
      try {
        const clanResponse = await axios.get("/api/clan_ranking_website.php");
        if (clanResponse.data && Array.isArray(clanResponse.data)) {
          setClans(clanResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchClanData();
    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchClanData, 2000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (seasonEndTimestamp) {
      const countdownIntervalId = setInterval(() => {
        const currentTimestampInCST = moment().tz("America/Chicago").valueOf();
        const remainingTime = Math.max(0, seasonEndTimestamp - currentTimestampInCST);
        setCountdown(remainingTime);
      }, 1000);

      return () => clearInterval(countdownIntervalId);
    }
  }, [seasonEndTimestamp]);

  const fetchClanMembers = async (clanId, clanName) => {
    try {
      const response = await axios.get(
        `/api/detail_clan_website.php?clan_id=${clanId}`
      );
      if (response.data && response.data.members) {
        setActiveClanMembers(response.data.members);
        setActiveClanId(clanId);
        setActiveClanName(clanName);
        setViewingMainRanking(false);
      }
    } catch (error) {
      console.error("Error fetching clan members:", error);
    }
  };

  const goBackToMainRanking = () => {
    setActiveClanId(null);
    setActiveClanName("");
    setActiveClanMembers([]);
    setViewingMainRanking(true);
  };

  const formatTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = formatTime(countdown);

  const timeSegments = [
    { value: days, label: "Days" },
    { value: hours, label: "Hours" },
    { value: minutes, label: "Minutes" },
    { value: seconds, label: "Seconds" }
  ];

  return (
    <div className="clan-ranking">
      <div className="clan-ranking-content">
        <h1 className="clan-ranking-header">
          {seasonNumber ? `Season ${seasonNumber} Clan Ranking` : "Clan Ranking"}
        </h1>

        <div className="countdown-container">
          <div className="countdown-clock">
            {timeSegments.map((segment, index) => (
              <div key={index} className="clock-segment">
                <div className="clock-number">{segment.value}</div>
                <div className="clock-label">{segment.label}</div>
              </div>
            ))}
          </div>
        </div>

        {viewingMainRanking ? (
          <div className="table-wrapper">
            <div className="table-scroll">
              <table>
                <colgroup>
                  <col style={{ width: "80px" }} />
                  <col style={{ width: "200px" }} />
                  <col style={{ width: "200px" }} />
                  <col style={{ width: "120px" }} />
                  <col style={{ width: "120px" }} />
                  <col style={{ width: "120px" }} />
                  <col style={{ width: "120px" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Name</th>
                    <th>Master</th>
                    <th>Member No.</th>
                    <th>Reputation</th>
                    <th>Gap from Rank 1</th>
                    <th>Gap</th>
                  </tr>
                </thead>
                <tbody>
                  {clans.map((clan, index) => {
                    const gapFromRank1 = clans[0].clan_reputation - clan.clan_reputation;
                    const prevClan = clans[index - 1];
                    const gapFromAbove = prevClan
                      ? prevClan.clan_reputation - clan.clan_reputation
                      : null;

                    return (
                      <tr
                        key={clan.clan_id}
                        onClick={() => fetchClanMembers(clan.clan_id, clan.clan_name)}
                        className={index % 2 === 0 ? "row-even" : "row-odd"}
                      >
                        <td>
                          {index + 1}
                          {getRankIcon(index + 1)}
                        </td>
                        <td>{clan.clan_name}</td>
                        <td>{clan.character_name}</td>
                        <td>{`${clan.clan_members}/${clan.clan_max_members}`}</td>
                        <td>{clan.clan_reputation}</td>
                        <td>{gapFromRank1}</td>
                        <td>{gapFromAbove}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="clan-members-container">
            <button className="back-button" onClick={goBackToMainRanking}>
              Back to Rankings
            </button>
            <ClanMembers
              members={activeClanMembers}
              clanName={activeClanName}
              onBackClick={goBackToMainRanking}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ClanRanking;

import React from 'react';
import '../style/CrewMembers.scss';

const CrewMembers = ({ members, crewName, onBackClick }) => {
  return (
    <div className="crew-members">
      <h2 className="crew-members-header">{crewName} Crew Members</h2>
      <button className="back-button" onClick={onBackClick}>
        Back to Crew Ranking
      </button>
      
      <div className="table-wrapper">
        <div className="table-scroll">
          <table>
            <thead>
              <tr>
                <th>Character Name</th>
                <th>Level</th>
                <th>Boss Points</th>
                <th>Damage</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "row-even" : "row-odd"}
                >
                  <td>{member.character_name}</td>
                  <td>{member.character_level}</td>
                  <td>{member.boss_kills}</td>
                  <td>{member.member_damage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CrewMembers;
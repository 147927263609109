import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box
} from '@mui/material';
import $ from 'jquery';

function VerificationCodePopup({ open, onClose, accountId, username, onVerificationSuccess }) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!code) {
      setError('Please enter the verification code');
      return;
    }

    setIsSubmitting(true);
    setError('');

    $.ajax({
      type: "POST",
      url: "https://ninjarift.org/account/VerifyAccount.php",
      data: {
        acc_id: accountId,
        code: code.trim()
      },
      success(data) {
        setIsSubmitting(false);
        
        if (data.verified) {
          $.ajax({
            type: "POST",
            url: "https://ninjarift.org/account/VerifyAccount.php",
            data: {
              acc_id: data.acc_id,
              web_session_key: data.web_session_key
            },
            success(sessionData) {
              if (sessionData.valid) {
                onVerificationSuccess(data);
              } else {
                setError('Session verification failed. Please try again.');
              }
            },
            error() {
              setError('Failed to verify session. Please try again.');
            }
          });
        } else if (data.error) {
          setError(data.error);
        } else {
          setError('Invalid verification response');
        }
      },
      error(jqXHR) {
        setIsSubmitting(false);
        setError('Failed to verify code. Please try again.');
        try {
          const response = JSON.parse(jqXHR.responseText);
          if (response.error) {
            setError(response.error);
          }
        } catch (e) {
          // Use default error message
        }
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Verify Your Account</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" gutterBottom>
            Hello {username},
          </Typography>
          <Typography variant="body1" gutterBottom>
            A verification code has been sent to your email address. Please enter it below to complete your login.
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            label="Verification Code"
            name="code"
            autoFocus
            value={code}
            onChange={(e) => setCode(e.target.value)}
            error={!!error}
            helperText={error}
            sx={{ mt: 2 }}
            inputProps={{
              maxLength: 6,
              pattern: '[0-9]*'
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={onClose} color="secondary" disabled={isSubmitting}>
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color="primary"
          disabled={isSubmitting || code.length !== 6}
        >
          {isSubmitting ? 'Verifying...' : 'Verify'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VerificationCodePopup;
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaBug, FaStar, FaWrench } from 'react-icons/fa';
import '../style/PatchNotes.scss';
import patchNotesData from './patchNotesData.json';

const PatchNotes = () => {
  const [expandedVersion, setExpandedVersion] = useState(null);

  const toggleExpansion = (version) => {
    setExpandedVersion(expandedVersion === version ? null : version);
  };

  const groupNotesByType = (notes) => {
    const groups = {
      new: notes.filter(note => note.type === 'new'),
      bug: notes.filter(note => note.type === 'bug')
    };
    return groups;
  };

  const renderNoteSection = (notes, type) => {
    if (!notes || notes.length === 0) return null;

    const icons = {
      new: <FaStar className="icon new-icon" />,
      bug: <FaBug className="icon bug-icon" />
    };

    const titles = {
      new: 'New Features & Improvements',
      bug: 'Bug Fixes'
    };

    return (
      <div className={`notes-section ${type}-section`}>
        <div className="section-header">
          {icons[type]}
          <h4>{titles[type]}</h4>
        </div>
        <ul>
          {notes.map((note, index) => (
            <li key={index} className={`note ${type}`}>
              <span className="note-bullet">•</span>
              <span className="note-text">{note.text}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderThumbnail = (thumbnail) => {
    try {
      const image = require(`../img/PatchNotes/${thumbnail}`);
      return <img src={image} alt="Update thumbnail" className="expanded-thumbnail" />;
    } catch (error) {
      console.error('Error loading thumbnail:', error);
      return null;
    }
  };

  return (
    <div className="patch-notes-container">
      <div className="patch-notes-list">
        {patchNotesData.map((patch) => {
          const groupedNotes = groupNotesByType(patch.notes);
          return (
            <div key={patch.version} className="patch-notes-item" onClick={() => toggleExpansion(patch.version)}>
              <div className="patch-notes-thumbnail">
                {renderThumbnail(patch.thumbnail)}
                <div className="patch-notes-header">
                  <div className="version-info">
                    <h3>Version {patch.version}</h3>
                    <span className="date">{patch.date}</span>
                  </div>
                  <div className="toggle-icon">
                    {expandedVersion === patch.version ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
              </div>
              {expandedVersion === patch.version && (
                <div className="patch-notes-content">
                  <div className="notes-sections">
                    {renderNoteSection(groupedNotes.new, 'new')}
                    {renderNoteSection(groupedNotes.bug, 'bug')}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PatchNotes;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './login.scss';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [needsVerification, setNeedsVerification] = useState(false);
  const [accountData, setAccountData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();

  useEffect(() => {
    // Check if we already have a valid session
    const checkSession = async () => {
      try {
      const formData = new URLSearchParams();
      formData.append('action', 'check_session');
      
      const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRF-Token': document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1] || ''
          },
          body: formData
        });

        const data = await response.json();
        if (data.valid) {
          await login();
          // Small delay to ensure auth state is updated
          await new Promise(resolve => setTimeout(resolve, 100));
          navigate('/strategu');
        }
      } catch (err) {
        console.error('Session check error:', err);
      }
    };

    checkSession();
  }, [navigate, login]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const formData = new URLSearchParams();
      formData.append('action', 'login');
      formData.append('mail', email);
      formData.append('password', password);

      const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1] || ''
        },
        body: formData
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
        return;
      }

      if (data.needs_verification) {
        setNeedsVerification(true);
        setAccountData({
          acc_id: data.acc_id,
          username: data.username
        });
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to connect to server');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
        const formData = new URLSearchParams();
        formData.append('action', 'verify');
        formData.append('code', verificationCode);
        formData.append('acc_id', accountData.acc_id);

        const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRF-Token': document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1] || ''
            },
            body: formData
        });

        const data = await response.json();
        console.log('Verification response:', data);

        if (data.error) {
            setError(data.error);
            return;
        }

        if (data.verified) {
            try {
                console.log('Login successful, storing token:', data.csrf_token);
                await login(data.csrf_token);
                navigate('/strategu');
            } catch (err) {
                console.error('Login state update error:', err);
                setError('Failed to update login state');
            }
        } else {
            setError('Verification failed');
        }
    } catch (err) {
        console.error('Verification error:', err);
        setError('Failed to verify code');
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <div className="strategic-login">
      <div className="login-container">
        <h1>Developer Login</h1>
        {error && <div className="error-message">{error}</div>}
        
        {!needsVerification ? (
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                disabled={isLoading}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
                disabled={isLoading}
              />
            </div>
            <button 
              type="submit"
              className={isLoading ? 'loading' : ''}
              disabled={isLoading}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleVerification}>
            <div className="verification-message">
              A verification code has been sent to your email.
            </div>
            <div className="form-group">
              <label htmlFor="verificationCode">Verification Code</label>
              <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Enter 6-digit code"
                maxLength="6"
                required
                disabled={isLoading}
              />
            </div>
            <button 
              type="submit"
              className={isLoading ? 'loading' : ''}
              disabled={isLoading}
            >
              {isLoading ? 'Verifying...' : 'Verify'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;

import React from "react";
import "../style/ClanMembers.scss";

function ClanMembers({ members, clanName, onBackClick }) {
  return (
    <div className="clan-members">
      <h2 className="clan-members-header">{clanName} Members</h2>
      <div className="table-wrapper">
        <div className="table-scroll">
          <table className="member-table">
            <thead>
              <tr>
                <th>Character Name</th>
                <th>Level</th>
                <th>Reputation</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "row-even" : "row-odd"}
                >
                  <td>{member.character_name}</td>
                  <td>{member.character_level}</td>
                  <td>{member.member_reputation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ClanMembers;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './strategu.scss';
import ServerSettings from './ServerSettings';
import CharacterLookup from './CharacterLookup';
import ItemManager from './ItemManager';

const Strategu = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('server');
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { isLoggedIn, setIsLoggedIn, csrfToken } = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setIsLoading(true);

        const storedToken = localStorage.getItem('csrf_token');
        console.log('Stored CSRF token:', storedToken);

        if (!storedToken) {
          console.log('No stored CSRF token found');
          setIsLoggedIn(false);
          setIsLoading(false);
          navigate('/strategu/login');
          return;
        }

        const formData = new URLSearchParams();
        formData.append('action', 'check_session');

        const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRF-Token': storedToken
          },
          body: formData
        });

        const data = await response.json();
        console.log('Session check response:', data);

        if (!data.valid) {
          console.log('Session invalid, clearing token');
          localStorage.removeItem('csrf_token');
          setIsLoggedIn(false);
          setIsLoading(false);
          navigate('/strategu/login');
          return;
        }

        setUsername(data.username);
        setIsLoggedIn(true);
        setIsLoading(false);
      } catch (err) {
        console.error('Session verification error:', err);
        localStorage.removeItem('csrf_token');
        setIsLoggedIn(false);
        setIsLoading(false);
        navigate('/strategu/login');
      }
    };

    checkAuth();
    const checkInterval = setInterval(checkAuth, 300000);
    return () => clearInterval(checkInterval);
  }, [navigate, setIsLoggedIn]);

  const { logout } = useAuth();
  
  const handleLogout = async () => {
    await logout();
    navigate('/strategu/login');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return null; // Let the navigation handle the redirect
  }

  return (
    <div className="content-container">
      <div className="h1-dashboard">
        <div className="title-section">
          <span className="title">Developer Dashboard</span>
          <span className="username">Welcome, {username}</span>
        </div>
        <button className="logout-btn" onClick={handleLogout}>Logout</button>
      </div>

      <div className="data-container">
        <div className="tabs">
          <button 
            className={activeTab === 'server' ? 'active' : ''} 
            onClick={() => setActiveTab('server')}
          >
            Server Settings
          </button>
          <button 
            className={activeTab === 'characters' ? 'active' : ''} 
            onClick={() => setActiveTab('characters')}
          >
            Character Lookup
          </button>
          <button 
            className={activeTab === 'items' ? 'active' : ''} 
            onClick={() => setActiveTab('items')}
          >
            Items
          </button>
        </div>

        {activeTab === 'server' && <ServerSettings />}
        {activeTab === 'characters' && <CharacterLookup />}
        {activeTab === 'items' && <ItemManager />}
      </div>
    </div>
  );
};

export default Strategu;

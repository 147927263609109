import React from "react";

// Import league rank images
import rank1 from "../img/Leagues/1.png";
import rank2 from "../img/Leagues/2.png";
import rank3 from "../img/Leagues/3.png";
import rank4 from "../img/Leagues/4.png";
import rank5 from "../img/Leagues/5.png";
import rank6 from "../img/Leagues/6.png";
import rank7 from "../img/Leagues/7.png";
import rank8 from "../img/Leagues/8.png";

const CharacterCard = ({
  character,
  classImages,
  elementImages,
  rankImages,
  talentImages,
  villageImages,
  xpImg,
  goldImg,
  tpImg,
  ssImg,
  clanImg,
  crewImg,
  prestigeImg,
  arenaEnergyImg,
  arenaTrophiesImg,
  rollsImg,
  repImg,
  arenaImg,
  bossKillImg,
  damageImg,
  meritImg,
  crewRollsImg,
  crewVitalityImg,
}) => {
  const formatNumber = (num) => {
    return parseInt(num).toLocaleString();
  };

  const leagueRankImages = {
    1: rank1,
    2: rank2,
    3: rank3,
    4: rank4,
    5: rank5,
    6: rank6,
    7: rank7,
    8: rank8
  };

  const getTrophyRank = (trophies) => {
    if (trophies >= 10000) return 8;
    if (trophies >= 7500) return 7;
    if (trophies >= 5000) return 6;
    if (trophies >= 3000) return 5;
    if (trophies >= 2000) return 4;
    if (trophies >= 1000) return 3;
    if (trophies >= 500) return 2;
    return 1;
  };

  const calculateXpProgress = () => {
    const currentLevel = parseInt(character.character_level);
    const currentXp = parseInt(character.character_xp);

    // Get required XP for current level
    const requiredXp = getRequiredXp(currentLevel);

    // Calculate percentage
    const percentage = (currentXp / requiredXp) * 100;
    return Math.min(percentage, 100); // Cap at 100%
  };

  const getRequiredXp = (level) => {
    const xpTable = {
      1: 15,
      2: 304,
      3: 493,
      4: 711,
      5: 961,
      6: 1247,
      7: 1574,
      8: 1945,
      9: 2366,
      10: 2843,
      11: 3382,
      12: 3989,
      13: 4673,
      14: 5542,
      15: 6306,
      16: 7273,
      17: 8537,
      18: 9569,
      19: 10922,
      20: 12433,
      21: 14117,
      22: 15992,
      23: 18080,
      24: 20401,
      25: 22981,
      26: 25845,
      27: 29024,
      28: 32548,
      29: 36454,
      30: 40780,
      31: 45569,
      32: 50867,
      33: 56725,
      34: 63201,
      35: 70354,
      36: 78254,
      37: 86973,
      38: 96593,
      39: 107202,
      40: 118899,
      41: 131790,
      42: 145991,
      43: 161632,
      44: 178850,
      45: 197801,
      46: 218652,
      47: 241587,
      48: 266806,
      49: 294530,
      50: 325000,
      51: 358478,
      52: 395253,
      53: 435640,
      54: 479982,
      55: 528656,
      56: 582073,
      57: 640648,
      58: 704980,
      59: 775497,
      60: 858822,
      61: 973598,
      62: 1030523,
      63: 1132364,
      64: 1243956,
      65: 1366211,
      66: 1500266,
      67: 1646789,
      68: 1807388,
      69: 1983211,
      70: 2175702,
      71: 2386377,
      72: 2616933,
      73: 2869211,
      74: 3145217,
      75: 3447146,
      76: 3777386,
      77: 4138547,
      78: 4533474,
      79: 4965272,
      80: 5437326,
      81: 5953328,
      82: 6517304,
      83: 7133648,
      84: 7807145,
      85: 8543017,
      86: 9346956,
      87: 10512475,
      88: 11184414,
      89: 12232070,
      90: 13376173,
      91: 14625481,
      92: 15825481,
      93: 17422318,
      94: 19336471,
      95: 20879013,
      96: 23921871,
      97: 25599188,
      98: 27225299,
      99: 29753575,
      100: 32500000,
    };
    return xpTable[level] || 99999999;
  };

  const getElements = () => {
    const elements = [];
    for (let i = 1; i <= 5; i++) {
      const elementId = character[`character_element_${i}`];
      if (elementId && elementId !== 0) {
        elements.push(
          <img
            key={`element-${i}`}
            src={elementImages[elementId]}
            alt={`Element ${elementId}`}
          />
        );
      }
    }
    return elements;
  };

  const getTalents = () => {
    const talents = [];
    for (let i = 1; i <= 3; i++) {
      const talent = character[`character_talent_${i}`];
      if (talent) {
        talents.push(
          <img key={`talent-${i}`} src={talentImages[talent]} alt={talent} />
        );
      }
    }
    return talents;
  };

  const getVillageName = (villageId) => {
    const villageNames = {
      1: "Wind Village",
      2: "Fire Village",
      3: "Lightning Village",
      4: "Earth Village",
      5: "Water Village",
    };
    return villageNames[villageId] || "Unknown Village";
  };

  return (
    <div className="character-card">
      <div className="card-header">
        <div className="name-section">
          <img
            src={rankImages[character.character_rank]}
            alt={`Rank ${character.character_rank}`}
          />
          <div className="name-id">
            <h3>{character.character_name}</h3>
            <span className="char-id">ID: {character.char_id}</span>
          </div>
        </div>
        {character.character_class && (
          <img
            className="class-image"
            src={classImages[character.character_class]}
            alt={character.character_class}
          />
        )}
      </div>

      <div className="stats-grid">
        <div className="stat-row">
          <div className="stat-item level">
            <div className="label">Level</div>
            <div className="value">{character.character_level}</div>
          </div>
          <div className="stat-item xp-item">
            <div className="label">XP</div>
            <div className="value">
              <img src={xpImg} alt="XP" />
              <span>
                {formatNumber(character.character_xp)} /{" "}
                {formatNumber(
                  getRequiredXp(parseInt(character.character_level))
                )}
              </span>
            </div>
            <div className="xp-progress">
              <div
                className="xp-progress-bar"
                style={{ width: `${calculateXpProgress()}%` }}
              />
            </div>
          </div>
        </div>
        <div className="stat-row">
          <div className="stat-item gold">
            <div className="label">Gold</div>
            <div className="value">
              <img src={goldImg} alt="Gold" />
              <span>{formatNumber(character.character_gold)}</span>
            </div>
          </div>
          <div className="stat-item tp">
            <div className="label">TP</div>
            <div className="value">
              <img src={tpImg} alt="TP" />
              <span>{formatNumber(character.character_tp)}</span>
            </div>
          </div>
        </div>
        {character.character_rank > 8 && (
          <div className="stat-row">
            <div className="stat-item sp">
              <div className="label">SS</div>
              <div className="value">
                <img src={ssImg} alt="SS" />
                <span>{formatNumber(character.character_sp)}</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="attributes">
        <div className="section">
          <div className="label">Elements</div>
          <div className="icons">{getElements()}</div>
        </div>

        {getTalents().length > 0 && (
          <div className="section">
            <div className="label">Talents</div>
            <div className="icons">{getTalents()}</div>
          </div>
        )}
      </div>

      {/* Clan Information */}
      {character.character_clan_info && (
        <div className="clan-section">
          <h3 className="section-title">Clan Information</h3>
          <div className="section-header">
            <img src={clanImg} alt="Clan" />
            <div className="name-rank">
              <span className="name">
                {character.character_clan_info.clan_name}
              </span>
              <span className="rank">
                Rank #{character.character_clan_info.clan_rank}
              </span>
            </div>
          </div>
          <div className="section-stats">
            <div className="stats-row">
              <div className="stat">
                <span className="label">Player Reputation </span>
                <img src={repImg} alt="Reputation" />
                <span>
                  {formatNumber(character.character_clan_info.reputation)}
                </span>
              </div>
            </div>
            <div className="stats-row">
              <div className="stat">
                <img src={prestigeImg} alt="Prestige" />
                <span>
                  {formatNumber(character.character_clan_info.prestige)}
                </span>
              </div>
              <div className="stat">
                <img src={rollsImg} alt="Rolls" />
                <span>{character.character_clan_info.rolls}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Crew Information */}
      {character.character_crew_info && (
        <div className="crew-section">
          <h3 className="section-title">Crew Information</h3>
          <div className="section-header">
            <img src={crewImg} alt="Crew" />
            <div className="name-rank">
              <span className="name">
                {character.character_crew_info.crew_name}
              </span>
              <span className="rank">
                Rank #{character.character_crew_info.crew_rank}
              </span>
            </div>
          </div>
          <div className="section-stats">
            <div className="stats-row">
              <div className="stat">
                <span className="label">Player </span>
                <img src={damageImg} alt="Damage" />
                <span>
                  {formatNumber(character.character_crew_info.damage)}
                </span>
              </div>
              <div className="stat">
                <span className="label">Player </span>
                <img src={bossKillImg} alt="Boss Kills" />
                <span>
                  {formatNumber(character.character_crew_info.boss_kills)}
                </span>
              </div>
            </div>
            <div className="stats-row">
              <div className="stat">
                <img src={crewVitalityImg} alt="Vitality Gourds" />
                <span>
                  {formatNumber(character.character_crew_info.vitality_gourds)}
                </span>
              </div>
              <div className="stat">
                <img src={crewRollsImg} alt="Stamina Rolls" />
                <span>
                  {formatNumber(character.character_crew_info.stamina_rolls)}
                </span>
              </div>
            </div>
            <div className="stats-row">
              <div className="stat">
                <img src={meritImg} alt="Merit" />
                <span>
                  {formatNumber(character.character_crew_info.crew_prestige)}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Arena Information */}
      {character.character_arena_info && (
        <div className="arena-section">
          <h3 className="section-title">Arena Information</h3>
          <div className="section-header">
            <div className="arena-header-left">
              <img
                src={villageImages[character.character_arena_info.village_id]}
                alt={`Village ${character.character_arena_info.village_id}`}
                className="arena-icon"
              />
            </div>
            <div className="arena-header-center">
              <span className="village-name">
                {getVillageName(character.character_arena_info.village_id)}
              </span>
              <span className="player-rank">
                Rank #{character.character_arena_info.player_rank}
              </span>
            </div>
            <div className="arena-header-right">
              <img 
                src={leagueRankImages[getTrophyRank(character.character_arena_info.trophies)]}
                alt={`League ${getTrophyRank(character.character_arena_info.trophies)}`}
                style={{ width: '4rem', height: '4rem' }}
              />
            </div>
          </div>
          <div className="section-stats">
            <div className="stats-row">
              <div className="stat">
                <span className="label">Player </span>
                <img src={arenaTrophiesImg} alt="Trophies" />
                <span>
                  {formatNumber(character.character_arena_info.trophies)}
                </span>
              </div>
              <div className="stat">
                <img src={arenaEnergyImg} alt="Energy" />
                <span>
                  {formatNumber(
                    character.character_arena_info?.energy_capsules ?? 0
                  )}
                </span>
              </div>
            </div>
            <div className="stats-row">
              <div className="stat">
                <span className="label">Village </span>
                <img src={arenaTrophiesImg} alt="Village Trophies" />
                <span>
                  {formatNumber(
                    character.character_arena_info.village_trophies
                  )}
                </span>
              </div>
              <div className="stat">
                <span className="label">Village Rank </span>
                <span>
                  {formatNumber(character.character_arena_info.village_rank)}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const CharacterCards = ({
  characters,
  classImages,
  elementImages,
  rankImages,
  talentImages,
  villageImages,
  xpImg,
  goldImg,
  tpImg,
  ssImg,
  clanImg,
  crewImg,
  prestigeImg,
  arenaEnergyImg,
  arenaTrophiesImg,
  rollsImg,
  repImg,
  arenaImg,
  bossKillImg,
  damageImg,
  meritImg,
  crewRollsImg,
  crewVitalityImg,
}) => {
  return (
    <div className="character-cards">
      <h2>Characters</h2>
      <div className="character-grid">
        {characters.map((character) => (
          <CharacterCard
            key={character.char_id}
            character={character}
            classImages={classImages}
            elementImages={elementImages}
            rankImages={rankImages}
            talentImages={talentImages}
            villageImages={villageImages}
            xpImg={xpImg}
            goldImg={goldImg}
            tpImg={tpImg}
            ssImg={ssImg}
            clanImg={clanImg}
            crewImg={crewImg}
            prestigeImg={prestigeImg}
            arenaEnergyImg={arenaEnergyImg}
            arenaTrophiesImg={arenaTrophiesImg}
            rollsImg={rollsImg}
            repImg={repImg}
            arenaImg={arenaImg}
            bossKillImg={bossKillImg}
            damageImg={damageImg}
            meritImg={meritImg}
            crewRollsImg={crewRollsImg}
            crewVitalityImg={crewVitalityImg}
          />
        ))}
      </div>
    </div>
  );
};

export default CharacterCards;

import React from 'react';
import './ServerSettings.scss';

const StatusMessage = ({ message }) => {
  if (!message) return null;

  return (
    <div className="settings-section">
      <div className={`status-message ${message.includes('Error') ? 'error' : 'success'}`}>
        {message}
      </div>
    </div>
  );
};

export default StatusMessage;

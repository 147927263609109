import React, { useState, useEffect, useRef } from 'react';
import {
  ArrowLeft,
  Bold,
  Italic,
  List,
  ListOrdered,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Link as LinkIcon,
  Image,
  AlertCircle,
  Type,
  X,
  Loader
} from 'lucide-react';
import './EndpointManager.scss';

const ImageGalleryModal = ({ isOpen, onClose, existingImages, onSelect }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Previously Used Images</h2>
          <button onClick={onClose} className="close-button">
            <X size={20} />
          </button>
        </div>
        <div className="image-grid">
          {existingImages.map((image, index) => (
            <div key={image.image_id} className="image-item">
              <img
                src={image.full_url}
                alt={`Previous upload ${index + 1}`}
                onClick={() => onSelect(image.full_url)}
              />
              <div className="image-info">
                <div className="image-date">
                  {new Date(image.uploaded_at).toLocaleDateString()}
                </div>
                {image.modified_by && (
                  <div className="image-modifier">
                    By: {image.modified_by}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ImagePreviewModal = ({ images, onClose, onConfirm }) => {
  if (!images?.length) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Preview Images</h2>
          <button onClick={onClose} className="close-button">
            <X size={20} />
          </button>
        </div>
        <div className="image-preview-grid">
          {images.map((image, index) => (
            <div key={index} className="preview-item">
              <img src={URL.createObjectURL(image)} alt={`Preview ${index + 1}`} />
              <div className="image-name">{image.name}</div>
            </div>
          ))}
        </div>
        <div className="modal-footer">
          <button onClick={onClose} className="cancel-button">Cancel</button>
          <button onClick={onConfirm} className="confirm-button">Upload & Insert</button>
        </div>
      </div>
    </div>
  );
};

const EndpointManager = ({ subcategoryId, subcategoryName, onBack }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState('');
  const [currentContentId, setCurrentContentId] = useState(null);
  const [existingImages, setExistingImages] = useState([]);
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const editorRef = useRef(null);
  const imageInputRef = useRef(null);

  useEffect(() => {
    const fetchLatestContent = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://playninjarift.com/server/admin/get_endpoint_contents.php?subcategory_id=${subcategoryId}`
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        if (result.status === 'error') {
          throw new Error(result.message);
        }

        const localDraft = localStorage.getItem(`draft_${subcategoryId}`);
        if (localDraft) {
          const draftData = JSON.parse(localDraft);
          const draftDate = new Date(draftData.lastSaved);

          if (result.data && result.data.length > 0) {
            const serverDate = new Date(result.data[0].updated_at);
            if (draftDate > serverDate) {
              setTitle(draftData.title || '');
              setContent(draftData.content || '');
              return;
            }
          } else {
            setTitle(draftData.title || '');
            setContent(draftData.content || '');
            return;
          }
        }

        if (result.data && result.data.length > 0) {
          const latestContent = result.data[0];
          setTitle(latestContent.title || '');
          setContent(latestContent.content || '');
          setCurrentContentId(latestContent.content_id);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
        setError('Failed to load content');
      } finally {
        setIsLoading(false);
      }
    };

    fetchLatestContent();
  }, [subcategoryId]);

  useEffect(() => {
    const fetchExistingImages = async () => {
      try {
        const response = await fetch(
          `https://playninjarift.com/server/admin/get_endpoint_images.php?subcategory_id=${subcategoryId}`
        );

        if (!response.ok) throw new Error('Failed to fetch images');

        const result = await response.json();
        if (result.status === 'success') {
          setExistingImages(result.data || []);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchExistingImages();
  }, [subcategoryId]);

  useEffect(() => {
    const editor = editorRef.current;
    if (!editor) return;

    const handleInput = () => {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      let container = range.commonAncestorContainer;

      if (container.nodeType === 3) {
        container = container.parentNode;
      }

      if (container === editor) {
        const p = document.createElement('p');
        p.style.direction = 'ltr';
        try {
          range.surroundContents(p);
        } catch (e) {
          p.appendChild(range.extractContents());
          range.insertNode(p);
        }
      }

      editor.querySelectorAll('p, div, h1, h2, h3, h4, h5, h6').forEach(block => {
        if (!block.style.direction) {
          block.style.direction = 'ltr';
        }
        if (!block.style.textAlign) {
          block.style.textAlign = 'left';
        }
      });

      setContent(editor.innerHTML);
    };

    editor.addEventListener('input', handleInput);
    return () => editor.removeEventListener('input', handleInput);
  }, []);

  const handleAlignment = (alignment) => {
    const editor = editorRef.current;
    if (!editor) return;

    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    let container = range.commonAncestorContainer;

    if (container.nodeType === 3) {
      container = container.parentNode;
    }

    if (container === editor) {
      const p = document.createElement('p');
      p.style.direction = 'ltr';
      try {
        range.surroundContents(p);
        container = p;
      } catch (e) {
        p.appendChild(range.extractContents());
        range.insertNode(p);
        container = p;
      }
    }

    while (container !== editor && !['P', 'DIV', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(container.nodeName)) {
      container = container.parentNode;
    }

    if (container !== editor) {
      container.style.textAlign = alignment;
      container.style.direction = 'ltr';
    }

    setContent(editor.innerHTML);
    editor.focus();
  };

  const saveDraftLocally = () => {
    try {
      if (!editorRef.current) return;

      const draftData = {
        title,
        content: editorRef.current.innerHTML,
        lastSaved: new Date().toISOString()
      };
      localStorage.setItem(`draft_${subcategoryId}`, JSON.stringify(draftData));
      setSuccess('Draft saved locally');
      setTimeout(() => setSuccess(''), 1500);
    } catch (error) {
      setError('Failed to save draft locally');
    }
  };

  const handlePublish = async () => {
    try {
      if (!editorRef.current) return;

      setIsSaving(true);
      setError(null);
      setSuccess('');

      if (!title.trim()) {
        throw new Error('Title is required');
      }

      const formData = new FormData();
      formData.append('subcategory_id', subcategoryId);
      formData.append('title', title.trim());
      formData.append('content', editorRef.current.innerHTML);
      formData.append('content_type', 'text');
      if (currentContentId) {
        formData.append('content_id', currentContentId);
      }

      const response = await fetch(
        'https://playninjarift.com/server/admin/save_endpoint_content.php',
        {
          method: 'POST',
          body: formData
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      if (result.status === 'error') {
        throw new Error(result.message);
      }

      localStorage.removeItem(`draft_${subcategoryId}`);
      setSuccess('Published successfully');

      if (result.data?.content_id) {
        setCurrentContentId(result.data.content_id);
      }

      setTimeout(() => {
        onBack();
      }, 1500);
    } catch (error) {
      setError(error.message || 'Failed to publish content');
      console.error('Publish error:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleImageSelection = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 10) {
      setError('You can only upload up to 10 images at once');
      return;
    }
    setSelectedImages(files);
  };

  const handleImageUpload = async (images) => {
    setIsUploading(true);
    try {
      const uploadPromises = images.map(async (file) => {
        const formData = new FormData();
        formData.append('subcategory_id', subcategoryId);
        formData.append('image', file);

        const response = await fetch(
          'https://playninjarift.com/server/admin/upload_endpoint_image.php',
          {
            method: 'POST',
            body: formData
          }
        );

        const result = await response.json();
        if (result.status === 'error') throw new Error(result.message);
        return result.full_url; // Using full_url from server response
      });

      const urls = await Promise.all(uploadPromises);

      const editor = editorRef.current;
      if (editor) {
        urls.forEach(url => {
          const img = document.createElement('img');
          img.src = url;
          img.alt = 'Uploaded content';
          editor.appendChild(img);
        });

        setContent(editor.innerHTML);
      }

      setSelectedImages(null);

      // Refresh existing images list
      const response = await fetch(
        `https://playninjarift.com/server/admin/get_endpoint_images.php?subcategory_id=${subcategoryId}`
      );
      const result = await response.json();
      if (result.status === 'success') {
        setExistingImages(result.data || []);
      }
    } catch (error) {
      setError(error.message || 'Failed to upload images');
    } finally {
      setIsUploading(false);
    }
  };

  const insertExistingImage = (url) => {
    const editor = editorRef.current;
    if (editor) {
      const img = document.createElement('img');
      img.src = url;
      img.alt = 'Existing content';
      editor.appendChild(img);
      setContent(editor.innerHTML);
      setIsImageGalleryOpen(false);
    }
  };

  const handleFontSize = (size) => {
    document.execCommand('fontSize', false, size);
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Loading content...</p>
      </div>
    );
  }

  return (
    <div className="endpoint-manager">
      <div className="top-bar">
        <button onClick={onBack} className="back-button">
          <ArrowLeft size={20} />
          Back to Subcategories
        </button>
        <div className="controls">
          <button
            className="save-draft"
            onClick={saveDraftLocally}
            disabled={isSaving}
          >
            Save Draft
          </button>
          <button
            className="publish"
            onClick={handlePublish}
            disabled={isSaving}
          >
            {isSaving ? 'Publishing...' : 'Publish'}
          </button>
        </div>
      </div>

      <div className="editor-container">
        <input
          type="text"
          className="title-input"
          placeholder="Add title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          dir="ltr"
        />

        <div className="toolbar">
          <button onClick={() => document.execCommand('bold')} title="Bold">
            <Bold size={18} />
          </button>
          <button onClick={() => document.execCommand('italic')} title="Italic">
            <Italic size={18} />
          </button>
          <div className="font-size-dropdown">
            <button className="font-size-button" title="Font Size">
              <Type size={18} />
            </button>
            <div className="font-size-options">
              <button onClick={() => handleFontSize(1)}>Small</button>
              <button onClick={() => handleFontSize(3)}>Normal</button>
              <button onClick={() => handleFontSize(5)}>Large</button>
              <button onClick={() => handleFontSize(7)}>Huge</button>
            </div>
          </div>
          <div className="separator" />
          <button onClick={() => document.execCommand('insertUnorderedList')} title="Bullet List">
            <List size={18} />
          </button>
          <button onClick={() => document.execCommand('insertOrderedList')} title="Numbered List">
            <ListOrdered size={18} />
          </button>
          <div className="separator" />
          <button
            onClick={() => handleAlignment('left')}
            title="Align Left"
            className="toolbar-button"
          >
            <AlignLeft size={18} />
          </button>
          <button
            onClick={() => handleAlignment('center')}
            title="Align Center"
            className="toolbar-button"
          >
            <AlignCenter size={18} />
          </button>
          <button
            onClick={() => handleAlignment('right')}
            title="Align Right"
            className="toolbar-button"
          >
            <AlignRight size={18} />
          </button>
          <button
            onClick={() => handleAlignment('justify')}
            title="Justify"
            className="toolbar-button"
          >
            <AlignJustify size={18} />
          </button>
          <div className="separator" />
          <div className="image-controls">
            <label className="file-upload" title="Upload Images">
              <input
                ref={imageInputRef}
                type="file"
                accept="image/*"
                onChange={handleImageSelection}
                multiple
                hidden
              />
              <Image size={18} />
            </label>
            <button
              onClick={() => setIsImageGalleryOpen(true)}
              className="existing-images"
              title="View Existing Images"
            >
              <Image size={18} />
            </button>
          </div>
        </div>

        <div
          ref={editorRef}
          className="editor-content"
          contentEditable
          dangerouslySetInnerHTML={{ __html: content }}
          dir="ltr"
          placeholder="Start writing or type '/' for commands..."
        />
      </div>

      <ImagePreviewModal
        images={selectedImages}
        onClose={() => {
          setSelectedImages(null);
          if (imageInputRef.current) {
            imageInputRef.current.value = '';
          }
        }}
        onConfirm={() => handleImageUpload(selectedImages)}
      />

      <ImageGalleryModal
        isOpen={isImageGalleryOpen}
        onClose={() => setIsImageGalleryOpen(false)}
        existingImages={existingImages}
        onSelect={insertExistingImage}
      />

      {isUploading && (
        <div className="loading-overlay">
          <Loader size={40} className="spinner" />
          <p>Uploading images...</p>
        </div>
      )}

      {error && (
        <div className="error">
          <AlertCircle size={20} />
          {error}
        </div>
      )}

      {success && (
        <div className="success">
          {success}
        </div>
      )}
    </div>
  );
};

export default EndpointManager;
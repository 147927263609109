import React, { useState, useEffect } from 'react';
import '../style/KekkaiSolver.scss';

// Import rune images
import blackRune from '../runes/black.png';
import blueRune from '../runes/blue.png';
import greenRune from '../runes/green.png';
import redRune from '../runes/red.png';
import whiteRune from '../runes/white.png';
import yellowRune from '../runes/yellow.png';
import rightIcon from '../runes/right.png';
import wrongIcon from '../runes/wrong.png';

const KekkaiSolver = () => {
  const [numRunes, setNumRunes] = useState(5);
  const [gameState, setGameState] = useState(null);
  const [oldGuesses, setOldGuesses] = useState([]);
  const [currentGuess, setCurrentGuess] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const RUNES = ['Green', 'Red', 'Blue', 'Black', 'Yellow', 'White'];
  const RUNE_IMAGES = {
    'Black': blackRune,
    'Blue': blueRune,
    'Green': greenRune,
    'Red': redRune,
    'White': whiteRune,
    'Yellow': yellowRune
  };

  const generateAllPossibleGuessesNoDups = (numRunes, runes) => {
    if (numRunes < 1) throw "numRunes must be at least 1.";
    if (numRunes === 1) return runes.map(rune => [rune]);
    
    const retVal = [];
    for (let index = 0; index < runes.length; index++) {
      const firstRune = runes[index];
      const remainingRunes = runes.filter((_, otherIndex) => otherIndex !== index);
      const suffixes = generateAllPossibleGuessesNoDups(numRunes - 1, remainingRunes);
      suffixes.forEach(suffix => {
        retVal.push([firstRune].concat(suffix));
      });
    }
    return retVal;
  };

  const judgeGuess = (answer, guess) => {
    const retVal = { bothCorrect: 0, colorCorrect: 0 };
    const usedAnswerIndices = new Set();
    const usedGuessIndices = new Set();
    
    // First pass: Find exact matches
    for (let i = 0; i < answer.length; i++) {
      if (answer[i] === guess[i]) {
        retVal.bothCorrect++;
        usedAnswerIndices.add(i);
        usedGuessIndices.add(i);
      }
    }
    
    // Second pass: Find color matches (wrong position)
    for (let i = 0; i < answer.length; i++) {
      if (usedAnswerIndices.has(i)) continue;
      
      for (let j = 0; j < guess.length; j++) {
        if (usedGuessIndices.has(j)) continue;
        
        if (answer[i] === guess[j]) {
          retVal.colorCorrect++;
          usedAnswerIndices.add(i);
          usedGuessIndices.add(j);
          break;
        }
      }
    }
    
    return retVal;
  };

  const guessContradictsSomeEvidence = (guess, evidences) => {
    for (const evidence of evidences) {
      const judgement = judgeGuess(guess, evidence.guess);
      if (judgement.bothCorrect !== evidence.bothCorrect || 
          judgement.colorCorrect !== evidence.colorCorrect) {
        return true;
      }
    }
    return false;
  };

  const generateNextGuess = (state, evidence) => {
    if (!state || !state.possibleGuesses || state.possibleGuesses.length === 0) return null;
    
    let guessIndex = Math.floor(Math.random() * state.possibleGuesses.length);
    let guess = state.possibleGuesses[guessIndex];
    state.possibleGuesses.splice(guessIndex, 1);
    
    while (guessContradictsSomeEvidence(guess, evidence)) {
      if (state.possibleGuesses.length < 1) return null;
      guessIndex = Math.floor(Math.random() * state.possibleGuesses.length);
      guess = state.possibleGuesses[guessIndex];
      state.possibleGuesses.splice(guessIndex, 1);
    }
    
    return guess;
  };

  const handleStart = () => {
    setError('');
    setSuccess(false);
    setOldGuesses([]);
    
    if (numRunes < 1) {
      setError('Select at least one rune');
      return;
    }
    
    if (RUNES.length < numRunes) {
      setError('Not enough runes available');
      return;
    }
    
    const possibleGuesses = generateAllPossibleGuessesNoDups(numRunes, RUNES);
    
    const newGameState = {
      numRunes,
      runes: RUNES,
      possibleGuesses
    };
    
    setGameState(newGameState);
    updateGuess(newGameState, []);
  };

  const updateGuess = (state = gameState, evidence = oldGuesses) => {
    const guess = generateNextGuess(state, evidence);
    if (guess === null) {
      if (evidence.length === 0) {
        setError('Failed to generate initial guess - please try again');
      } else if (state.possibleGuesses.length === 0) {
        setError('No possible solutions for the input entered - please check your inputs for accuracy');
      } else {
        setError('The input provided is contradictory - some numbers may be incorrect');
      }
      setCurrentGuess(null);
    } else {
      setCurrentGuess(guess);
      setError('');
    }
  };

  const handleGuessSubmit = (bothCorrect, colorCorrect) => {
    // Validate inputs are numbers
    if (isNaN(bothCorrect) || isNaN(colorCorrect)) {
      setError('Please enter valid numbers for both inputs');
      return;
    }

    // Validate inputs are non-negative
    if (bothCorrect < 0 || colorCorrect < 0) {
      setError('Both numbers must be 0 or greater');
      return;
    }

    // Validate individual values don't exceed number of runes
    if (bothCorrect > numRunes || colorCorrect > numRunes) {
      setError(`Numbers cannot exceed the total runes (${numRunes})`);
      return;
    }

    // In a no-duplicates game:
    // 1. Total matches cannot exceed number of runes (since each color can only match once)
    // 2. Total matches cannot be less than the number of colors used in both sequences
    //    (since each color must match somewhere if it appears in both sequences)
    const totalMatches = bothCorrect + colorCorrect;
    if (totalMatches > numRunes) {
      setError(`Total matches (${totalMatches}) cannot exceed the number of runes (${numRunes})`);
      return;
    }

    if (bothCorrect === numRunes) {
      setSuccess(true);
      return;
    }

    const newEvidence = {
      guess: currentGuess,
      bothCorrect,
      colorCorrect
    };

    setOldGuesses(prev => [...prev, newEvidence]);
    updateGuess(gameState, [...oldGuesses, newEvidence]);
  };

  return (
    <div className="KekkaiSolver">
      <h2>Kekkai Solver</h2>
      <div className="settingsContainer">
        <div className="settings">
          <div className="settingGroup">
            <label>Runes:</label>
            <input 
              type="number" 
              value={numRunes} 
              onChange={e => setNumRunes(parseInt(e.target.value))}
              min="1"
              max="10"
            />
          </div>
          <button className="restart" onClick={handleStart}>
            {oldGuesses.length > 0 ? 'Restart' : 'Start'}
          </button>
        </div>
      </div>

      {error && <div className="error">{error}</div>}
      {success && <div className="success">Congratulations! Click Restart to try again.</div>}

      {oldGuesses.length > 0 && (
        <div className="guessesTable">
          <table>
            <thead>
              <tr>
                <th>Guess</th>
                <th><img src={rightIcon} alt="right" className="feedbackIcon" /></th>
                <th><img src={wrongIcon} alt="wrong" className="feedbackIcon" /></th>
              </tr>
            </thead>
            <tbody>
              {oldGuesses.map((evidence, index) => (
                <tr key={index}>
                  <td className="runesDisplay">
                    {evidence.guess.map((rune, i) => (
                      <img key={i} src={RUNE_IMAGES[rune]} alt={rune} className="runeIcon" />
                    ))}
                  </td>
                  <td className="feedbackNumber">{evidence.bothCorrect}</td>
                  <td className="feedbackNumber">{evidence.colorCorrect}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {currentGuess && (
        <div className="currentGuess">
          <div className="guessDisplay">
            <span>Try:</span>
            <div className="runesRow">
              {currentGuess.map((rune, index) => (
                <img key={index} src={RUNE_IMAGES[rune]} alt={rune} className="runeIcon" />
              ))}
            </div>
          </div>
          <div className="feedbackInputs">
            <div className="feedbackSection">
              <div className="inputGroup">
                <div className="feedbackLabel">
                  <img src={rightIcon} alt="right" className="feedbackIcon" />
                  <label>Right position:</label>
                </div>
                <input 
                  type="number"
                  min="0"
                  max={numRunes}
                  defaultValue="0"
                  className="feedbackInput"
                  id="bothCorrect"
                  onInput={(e) => {
                    const val = parseInt(e.target.value);
                    if (isNaN(val) || val < 0) e.target.value = 0;
                    if (val > numRunes) e.target.value = numRunes;
                  }}
                />
              </div>
              <div className="inputGroup">
                <div className="feedbackLabel">
                  <img src={wrongIcon} alt="wrong" className="feedbackIcon" />
                  <label>Wrong position:</label>
                </div>
                <input 
                  type="number"
                  min="0"
                  max={numRunes}
                  defaultValue="0"
                  className="feedbackInput"
                  id="colorCorrect"
                  onInput={(e) => {
                    const val = parseInt(e.target.value);
                    if (isNaN(val) || val < 0) e.target.value = 0;
                    if (val > numRunes) e.target.value = numRunes;
                  }}
                />
              </div>
            </div>
            <button onClick={() => {
              const bothCorrect = parseInt(document.getElementById('bothCorrect').value);
              const colorCorrect = parseInt(document.getElementById('colorCorrect').value);
              handleGuessSubmit(bothCorrect, colorCorrect);
            }}>
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default KekkaiSolver;

import React, { useState } from 'react';
import './ServerSettings.scss';

const MaintenanceScheduler = ({
  maintenanceMode,
  maintenanceSchedule,
  onScheduleMaintenance,
  onCancelMaintenance,
  onMaintenanceToggle,
  formatDateTime,
  getDuration,
  getTimeFromNow,
  isLoading
}) => {
  const [scheduleForm, setScheduleForm] = useState({
    startTime: '',
    endTime: ''
  });

  const getCurrentChicagoTime = () => {
    const now = new Date();
    const chicagoTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    const year = chicagoTime.getFullYear();
    const month = String(chicagoTime.getMonth() + 1).padStart(2, '0');
    const day = String(chicagoTime.getDate()).padStart(2, '0');
    const hours = String(chicagoTime.getHours()).padStart(2, '0');
    const minutes = String(chicagoTime.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const formatToServerTime = (timeStr) => {
    if (!timeStr) return '';
    const [datePart, timePart] = timeStr.split('T');
    return `${datePart} ${timePart}:00`;
  };

  const handleScheduleSubmit = (e) => {
    e.preventDefault();
    if (scheduleForm.startTime && scheduleForm.endTime) {
      onScheduleMaintenance({
        startTime: formatToServerTime(scheduleForm.startTime),
        endTime: formatToServerTime(scheduleForm.endTime)
      });
      setScheduleForm({ startTime: '', endTime: '' });
    }
  };

  return (
    <div className="settings-section">
      <h3>Maintenance Scheduler</h3>
      <div className="content-wrapper">
        <div className="maintenance-status">
          <div className={`status-indicator ${maintenanceMode ? 'enabled' : 'disabled'}`}>
            {maintenanceMode ? 'Under Maintenance' : 'Live'}
          </div>
          <button 
            className={`toggle-btn ${maintenanceMode ? 'active' : ''}`}
            onClick={onMaintenanceToggle}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : maintenanceMode ? 'Disable' : 'Enable'}
          </button>
        </div>

        {!maintenanceSchedule && (
          <div className="schedule-controls">
            <form onSubmit={handleScheduleSubmit}>
              <div className="timezone-note">Enter times in server time (America/Chicago)</div>
              <div className="schedule-inputs">
                <div className="input-group">
                  <label>Start Time (Chicago)</label>
                  <input
                    type="datetime-local"
                    value={scheduleForm.startTime}
                    onChange={(e) => setScheduleForm(prev => ({
                      ...prev,
                      startTime: e.target.value,
                      endTime: prev.endTime && e.target.value > prev.endTime ? e.target.value : prev.endTime
                    }))}
                    min={getCurrentChicagoTime()}
                    required
                  />
                </div>
                <div className="input-group">
                  <label>End Time (Chicago)</label>
                  <input
                    type="datetime-local"
                    value={scheduleForm.endTime}
                    onChange={(e) => setScheduleForm(prev => ({
                      ...prev,
                      endTime: e.target.value
                    }))}
                    min={scheduleForm.startTime || getCurrentChicagoTime()}
                    required
                  />
                </div>
              </div>
              {scheduleForm.startTime && scheduleForm.endTime && (
                <div className="time-preview">
                  Duration: {getDuration(scheduleForm.startTime, scheduleForm.endTime)}
                </div>
              )}
              <div className="action-buttons">
                <button 
                  type="submit" 
                  className="schedule-btn"
                  disabled={isLoading}
                >
                  Schedule Maintenance
                </button>
              </div>
            </form>
          </div>
        )}

        {maintenanceSchedule && (
          <div className="scheduled-info">
            <div className="schedule-details">
              <p>
                <span>Start Time</span>
                <strong>{formatDateTime(maintenanceSchedule.start_time)}</strong>
              </p>
              <p>
                <span>End Time</span>
                <strong>{formatDateTime(maintenanceSchedule.end_time)}</strong>
              </p>
              <p>
                <span>Duration</span>
                <strong>{getDuration(maintenanceSchedule.start_time, maintenanceSchedule.end_time)}</strong>
              </p>
              <p>
                <span>Status</span>
                <span className={`status ${maintenanceSchedule.status}`}>
                  {maintenanceSchedule.status === 'scheduled' ? (
                    getTimeFromNow(maintenanceSchedule.start_time)
                  ) : (
                    maintenanceSchedule.status.replace('_', ' ')
                  )}
                </span>
              </p>
            </div>
            {maintenanceSchedule.status === 'scheduled' && (
              <div className="action-buttons">
                <button 
                  className="cancel-btn"
                  onClick={onCancelMaintenance}
                  disabled={isLoading}
                >
                  Cancel Scheduled Maintenance
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MaintenanceScheduler;

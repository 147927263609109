import React, { useState, useEffect } from 'react';
import {
  Plus,
  Upload,
  Pencil,
  Trash2,
  X,
  Check,
  User,
  ArrowLeft,
  ChevronRight,
  AlertCircle,
  Flag,
  Scroll
} from 'lucide-react';
import './SubcategoryManager.scss';
import EndpointManager from './EndpointManager';

const EditSubcategoryForm = ({ subcategory, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: subcategory.name,
    description: subcategory.description,
    icon: null,
  });
  const [previewImage, setPreviewImage] = useState(
    subcategory.icon_url || null
  );

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("File size must be less than 5MB");
        return;
      }

      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!allowedTypes.includes(file.type)) {
        alert("Only JPG, PNG and GIF files are allowed");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormData((prev) => ({ ...prev, icon: file }));
    }
  };

  return (
    <form
      className="edit-form"
      onSubmit={(e) => {
        e.preventDefault();
        onSave(formData);
      }}
    >
      <div className="form-content">
        <input
          type="text"
          value={formData.name}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, name: e.target.value }))
          }
          required
          className="input-field"
        />
        <input
          type="text"
          placeholder="Description"
          value={formData.description}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, description: e.target.value }))
          }
          className="input-field"
        />
        <div className="image-upload">
          <div className="category-icon mb-4">
            {previewImage ? (
              <img
                src={previewImage}
                alt="Preview"
                className="w-20 h-20 object-contain rounded-lg"
              />
            ) : subcategory.icon_url ? (
              <img
                src={subcategory.icon_url}
                alt={subcategory.name}
                className="w-20 h-20 object-contain rounded-lg"
              />
            ) : (
              <div className="w-20 h-20 bg-gray-100 rounded-lg flex items-center justify-center">
                <Upload size={24} className="text-gray-400" />
              </div>
            )}
          </div>
          <label className="upload-button">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden"
            />
            <Upload size={20} />
            {previewImage ? "Change Icon" : "Upload Icon"}
          </label>
        </div>
        <div className="actions">
          <button type="button" onClick={onCancel} className="cancel-button">
            <X size={20} />
          </button>
          <button type="submit" className="save-button">
            <Check size={20} />
          </button>
        </div>
      </div>
    </form>
  );
};

const SubcategoryManager = ({ categoryId, categoryName, onBack, parentId = null, isNested = false }) => {
  const [subcategories, setSubcategories] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [newSubcategory, setNewSubcategory] = useState({
    name: "",
    description: "",
    icon: null,
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [showEndpointManager, setShowEndpointManager] = useState(false);

  const fetchSubcategories = async () => {
    try {
      setIsLoading(true);
      const id = parentId || categoryId;
      const endpoint = parentId
        ? `get_nested_subcategories.php?parent_id=${id}`
        : `get_subcategories.php?category_id=${id}`;

      const response = await fetch(
        `https://playninjarift.com/server/admin/${endpoint}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Subcategories response:', result);

      if (result.status === "error") {
        throw new Error(result.message || 'Failed to fetch subcategories');
      }

      setSubcategories(result.data || []);
      setError(null);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setError(error.message || "Failed to load subcategories");
      setSubcategories([]);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleEndpoint = async (subcategoryId) => {
    try {
      const formData = new FormData();
      formData.append('subcategory_id', subcategoryId);

      const response = await fetch(
        'https://playninjarift.com/server/admin/mark_subcategory_endpoint.php',
        {
          method: 'POST',
          body: formData
        }
      );

      const result = await response.json();
      if (result.status === 'error') throw new Error(result.message);

      await fetchSubcategories(); // Refresh the list
      setError(null);
    } catch (error) {
      setError(error.message || 'Failed to update endpoint status');
    }
  };

  useEffect(() => {
    fetchSubcategories();
  }, [categoryId, parentId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image.*")) {
        setError("Please select an image file (JPG, PNG, or GIF)");
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setError("Image size must be less than 5MB");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setNewSubcategory(prev => ({ ...prev, icon: file }));
        setError(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newSubcategory.name.trim()) {
      setError("Subcategory name is required");
      return;
    }

    try {
      const formData = new FormData();
      const id = parentId || categoryId;
      if (parentId) {
        formData.append("parent_id", id);
      } else {
        formData.append("category_id", id);
      }
      formData.append("name", newSubcategory.name.trim());
      formData.append("description", newSubcategory.description.trim());
      if (newSubcategory.icon) {
        formData.append("icon", newSubcategory.icon);
      }

      const endpoint = parentId
        ? "add_nested_subcategory.php"
        : "add_subcategory.php";

      const response = await fetch(
        `https://playninjarift.com/server/admin/${endpoint}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      if (result.status === "error") {
        throw new Error(result.message);
      }

      setNewSubcategory({ name: "", description: "", icon: null });
      setPreviewImage(null);
      setError(null);
      await fetchSubcategories();
    } catch (error) {
      console.error("Error creating subcategory:", error);
      setError(error.message || "Failed to create subcategory");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleString();
  };

  if (selectedSubcategory) {
    if (selectedSubcategory.is_endpoint) {
      return (
        <EndpointManager
          subcategoryId={selectedSubcategory.subcategory_id}
          subcategoryName={selectedSubcategory.name}
          onBack={() => setSelectedSubcategory(null)}
        />
      );
    }
    return (
      <SubcategoryManager
        categoryId={categoryId}
        categoryName={selectedSubcategory.name}
        parentId={selectedSubcategory.subcategory_id}
        onBack={() => setSelectedSubcategory(null)}
        isNested={true}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Loading subcategories...</p>
      </div>
    );
  }

  return (
    <div className="subcategory-manager">
      <div className="subcategory-header">
        <button
          onClick={onBack}
          className="back-button flex items-center gap-2 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft size={20} />
          Back to {isNested ? 'Parent Subcategory' : 'Categories'}
        </button>
        <h2>Subcategories for {categoryName}</h2>
      </div>

      {error && (
        <div className="error mb-4 p-4 bg-red-100 text-red-700 rounded-md flex items-center gap-2">
          <AlertCircle size={20} />
          <span>{error}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="add-subcategory-form">
        <div className="form-group">
          <input
            type="text"
            placeholder="Subcategory Name"
            value={newSubcategory.name}
            onChange={(e) => {
              setError(null);
              setNewSubcategory(prev => ({ ...prev, name: e.target.value }));
            }}
            required
            className="focus:ring-2 focus:ring-indigo-500"
          />
          <input
            type="text"
            placeholder="Description"
            value={newSubcategory.description}
            onChange={(e) =>
              setNewSubcategory(prev => ({
                ...prev,
                description: e.target.value,
              }))
            }
            className="focus:ring-2 focus:ring-indigo-500"
          />
          <div className="image-upload">
            <label className="upload-button">
              <input
                type="file"
                accept="image/jpeg,image/png,image/gif"
                onChange={handleFileChange}
                className="hidden"
              />
              <Upload size={20} />
              {newSubcategory.icon ? "Change Icon" : "Upload Icon"}
            </label>
            {previewImage && (
              <div className="image-preview">
                <img
                  src={previewImage}
                  alt="Preview"
                  className="max-w-24 max-h-24 object-contain"
                />
              </div>
            )}
          </div>
          <button
            type="submit"
            className="add-button"
            disabled={!newSubcategory.name.trim()}
          >
            <Plus size={20} />
            Add Subcategory
          </button>
        </div>
      </form>

      <div className="subcategories-list">
        {subcategories.length > 0 ? (
          subcategories.map((subcategory) => (
            <div key={subcategory.subcategory_id} className="subcategory-item">
              {editingId === subcategory.subcategory_id ? (
                <EditSubcategoryForm
                  subcategory={subcategory}
                  onSave={async (updatedData) => {
                    try {
                      const formData = new FormData();
                      formData.append("subcategory_id", subcategory.subcategory_id);
                      formData.append("name", updatedData.name.trim());
                      formData.append("description", updatedData.description.trim());
                      if (updatedData.icon) {
                        formData.append("icon", updatedData.icon);
                      }

                      const response = await fetch(
                        "https://playninjarift.com/server/admin/update_subcategory.php",
                        {
                          method: "POST",
                          body: formData,
                        }
                      );

                      const result = await response.json();
                      if (result.status === "error") {
                        throw new Error(result.message);
                      }

                      await fetchSubcategories();
                      setEditingId(null);
                      setError(null);
                    } catch (error) {
                      console.error("Error updating subcategory:", error);
                      setError(error.message || "Failed to update subcategory");
                    }
                  }}
                  onCancel={() => setEditingId(null)}
                />
              ) : (
                <>
                  <div
                    className="subcategory-info cursor-pointer hover:bg-gray-50 rounded-lg transition-colors"
                    onClick={() => {
                      if (subcategory.is_endpoint) {
                        setShowEndpointManager(true);
                        setSelectedSubcategory(subcategory);
                      } else {
                        setSelectedSubcategory(subcategory);
                      }
                    }}
                  >
                    <div className="category-icon">
                      {subcategory.icon_url ? (
                        <img
                          src={subcategory.icon_url}
                          alt={subcategory.name}
                          className="w-20 h-20 object-contain rounded-lg"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://via.placeholder.com/60";
                          }}
                        />
                      ) : (
                        <div className="w-20 h-20 bg-gray-100 rounded-lg flex items-center justify-center">
                          <Scroll className="w-8 h-8 text-gray-400" />
                        </div>
                      )}
                    </div>
                    <div className="subcategory-content">
                      <div className="flex items-center gap-2">
                        <h3 className="text-lg font-semibold">{subcategory.name}</h3>
                        {subcategory.is_endpoint ? (
                          <Flag size={16} className="text-blue-500" />
                        ) : (
                          <ChevronRight size={16} className="text-gray-400" />
                        )}
                      </div>
                      <p className="text-gray-600">{subcategory.description}</p>
                      <div className="metadata text-sm text-gray-500">
                        {subcategory.modified_by && (
                          <div className="flex items-center gap-2">
                            <User size={14} />
                            <span>Modified by: {subcategory.modified_by}</span>
                          </div>
                        )}
                        <div className="text-xs">
                          Created: {formatDate(subcategory.created_at)}
                          <br />
                          Updated: {formatDate(subcategory.updated_at)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="actions">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleEndpoint(subcategory.subcategory_id);
                      }}
                      className={`p-2 rounded-full ${subcategory.is_endpoint
                        ? 'hover:bg-yellow-100 text-yellow-600'
                        : 'hover:bg-blue-100 text-blue-600'
                        }`}
                      title={subcategory.is_endpoint ? "Remove Endpoint Status" : "Mark as Endpoint"}
                    >
                      <Flag size={20} />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingId(subcategory.subcategory_id);
                      }}
                      className="p-2 hover:bg-gray-100 rounded-full"
                    >
                      <Pencil size={20} />
                    </button>
                    <button
                      className="delete p-2 hover:bg-red-100 rounded-full"
                      onClick={async (e) => {
                        e.stopPropagation();
                        if (window.confirm("Are you sure you want to delete this subcategory?")) {
                          try {
                            const formData = new FormData();
                            formData.append("subcategory_id", subcategory.subcategory_id);

                            const response = await fetch(
                              "https://playninjarift.com/server/admin/delete_subcategory.php",
                              {
                                method: "POST",
                                body: formData,
                              }
                            );

                            const result = await response.json();
                            if (result.status === "error") {
                              throw new Error(result.message);
                            }

                            await fetchSubcategories();
                            setError(null);
                          } catch (error) {
                            console.error("Error deleting subcategory:", error);
                            setError(error.message || "Failed to delete subcategory");
                          }
                        }
                      }}
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </>
              )}
            </div>
          ))
        ) : (
          <div className="text-center p-4 text-gray-500">
            No subcategories found
          </div>
        )}
      </div>
    </div>
  );
};

export default SubcategoryManager;
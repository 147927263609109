import React, { useState, useEffect } from 'react';
import { User, Loader } from 'lucide-react';
import './UserManager.scss';

const UserManager = ({ searchQuery }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const API_URL = 'https://playninjarift.com/server/admin/getUsers.php';

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(API_URL, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Server response:', data);

      if (data.status === 'success') {
        setUsers(data.data);
      } else {
        setError(data.message || 'Failed to fetch users');
      }
    } catch (err) {
      console.error('Fetch error:', err);
      setError('Connection error: Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  const filteredUsers = users.filter(user =>
    user.full_name?.toLowerCase().includes(searchQuery?.toLowerCase() || '')
  );

  if (loading) {
    return (
      <div className="loading-state">
        <Loader />
        <span>Loading users...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-state">
        <p>Error loading users</p>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="user-manager">
      <div className="section-header">
        <h2>User Management</h2>
        <p>View and manage system users</p>
      </div>

      <div className="users-table">
        <table>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Last Login</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <tr key={user.admin_id}>
                  <td>
                    <div className="user-icon">
                      <User />
                      <span>{user.full_name || 'N/A'}</span>
                    </div>
                  </td>
                  <td>{user.last_login ? new Date(user.last_login).toLocaleString() : 'Never'}</td>
                  <td>{user.created_at ? new Date(user.created_at).toLocaleString() : 'N/A'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="empty-state">
                  No users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserManager;
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [csrfToken, setCsrfToken] = useState(null);

  // Initialize from localStorage on mount
  useEffect(() => {
    const storedToken = localStorage.getItem('csrf_token');
    if (storedToken) {
      setCsrfToken(storedToken);
      setIsLoggedIn(true);
    }
  }, []);

  const login = async (token) => {
    if (token) {
      localStorage.setItem('csrf_token', token);
      setCsrfToken(token);
      setIsLoggedIn(true);
    }
  };

  const logout = async () => {
    try {
      const response = await fetch('https://ninjarift.org/adminpanel/logout.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrfToken || ''
        }
      });

      if (response.ok) {
        localStorage.removeItem('csrf_token');
        setCsrfToken(null);
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Logout error:', error);
      // Clear tokens even if logout request fails
      localStorage.removeItem('csrf_token');
      setCsrfToken(null);
      setIsLoggedIn(false);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isLoggedIn, 
      setIsLoggedIn, 
      csrfToken, 
      setCsrfToken,
      login, 
      logout 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); 
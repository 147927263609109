import React from 'react';
import './ServerSettings.scss';

const ClanSeasonManager = ({
  clanSeasonInfo,
  isLoading,
  onEndSeason,
  onSaveResults,
  onStartNewSeason,
  formatDateTime
}) => {
  return (
    <div className="settings-section">
      <h3>Clan Season Management</h3>
      <div className="content-wrapper">
        <div className="season-info">
          <p>
            <span>Current Season</span>
            <strong>{clanSeasonInfo.currentSeason}</strong>
          </p>
          <p>
            <span>Status</span>
            <span className={`status ${clanSeasonInfo.isActive ? 'active' : 'ended'}`}>
              {clanSeasonInfo.isActive ? 'Active' : 'Season Ended'}
            </span>
          </p>
          {!clanSeasonInfo.isActive && (
            <p>
              <span>Last Season End</span>
              <strong>{formatDateTime(clanSeasonInfo.endDate)}</strong>
            </p>
          )}
          {!clanSeasonInfo.isActive && (
            <p>
              <span>Results Status</span>
              <span className={`status ${clanSeasonInfo.resultsSaved ? 'saved' : 'not-saved'}`}>
                {clanSeasonInfo.resultsSaved ? 'Results Saved' : 'Results Not Saved'}
              </span>
            </p>
          )}
          {clanSeasonInfo.isActive && clanSeasonInfo.endDate && (
            <p>
              <span>Season Ends</span>
              <strong>{formatDateTime(clanSeasonInfo.endDate)}</strong>
            </p>
          )}
        </div>

        <div className="season-controls">
          {clanSeasonInfo.isActive ? (
            <button
              className="end-season-btn"
              onClick={onEndSeason}
              disabled={isLoading}
            >
              End Current Season
            </button>
          ) : (
            <>
              {!clanSeasonInfo.resultsSaved && (
                <button
                  className="save-results-btn"
                  onClick={onSaveResults}
                  disabled={isLoading}
                >
                  Save Season Results
                </button>
              )}
              <button
                className="start-season-btn"
                onClick={onStartNewSeason}
                disabled={isLoading || !clanSeasonInfo.resultsSaved}
              >
                Start New Season
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClanSeasonManager;

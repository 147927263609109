import React, { useState } from 'react';
import './ItemManager.scss';

const ItemManager = () => {
  const [selectedCategory, setSelectedCategory] = useState('currency');
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const handleAddItem = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    
    setIsLoading(true);
    setStatusMessage('');

    const sessionData = localStorage.getItem('strategicSession');
    if (!sessionData) return;

    try {
      const { acc_id, web_session_key } = JSON.parse(sessionData);
      const apiFormData = new URLSearchParams();
      apiFormData.append('acc_id', acc_id);
      apiFormData.append('web_session_key', web_session_key);
      apiFormData.append('action', 'add_item');
      apiFormData.append('category', selectedCategory);
      
      for (let [key, value] of formData.entries()) {
        apiFormData.append(key, value);
      }

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: apiFormData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage('Item added successfully');
        form.reset();
      } else {
        setStatusMessage(data.error || 'Error adding item');
      }
    } catch (err) {
      console.error('Error adding item:', err);
      setStatusMessage('Error adding item');
    } finally {
      setIsLoading(false);
    }
  };

  const renderCurrencyForm = () => (
    <form onSubmit={handleAddItem}>
      <div className="form-group">
        <label>Currency Type</label>
        <select name="currency_type" required>
          <option value="">Select Currency Type</option>
          <option value="tokens">Tokens</option>
          <option value="gold">Gold</option>
        </select>
      </div>
      <div className="form-group">
        <label>Amount</label>
        <input type="number" name="amount" min="1" required />
      </div>
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Adding...' : 'Add Currency'}
      </button>
    </form>
  );

  const renderRewardsForm = () => (
    <form onSubmit={handleAddItem}>
      <div className="form-group">
        <label>Reward Type</label>
        <select name="reward_type" required>
          <option value="">Select Reward Type</option>
          <option value="clan">Clan Rewards (Season 1)</option>
          <option value="crew">Crew Rewards (Season 1)</option>
          <option value="arena">Arena Rewards (Season 1)</option>
          <option value="pvp">PvP Rewards (Season 1)</option>
        </select>
      </div>
      <div className="form-group">
        <label>Reward ID</label>
        <input type="number" name="reward_id" min="1" required />
      </div>
      <div className="form-group">
        <label>Amount</label>
        <input type="number" name="amount" min="1" required />
      </div>
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Adding...' : 'Add Reward'}
      </button>
    </form>
  );

  const renderItemsForm = () => (
    <form onSubmit={handleAddItem}>
      <div className="form-group">
        <label>Item Category</label>
        <select name="item_category" required>
          <option value="">Select Item Category</option>
          <option value="weapons">Weapons</option>
          <option value="back">Back Items</option>
          <option value="sets">Sets</option>
          <option value="accessories">Accessories</option>
          <option value="hairstyles">Hairstyles</option>
          <option value="materials">Materials</option>
          <option value="consumables">Consumables</option>
        </select>
      </div>
      <div className="form-group">
        <label>Item ID</label>
        <input type="number" name="item_id" min="1" required />
      </div>
      <div className="form-group">
        <label>Amount</label>
        <input type="number" name="amount" min="1" required />
      </div>
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Adding...' : 'Add Item'}
      </button>
    </form>
  );

  const renderPetsForm = () => (
    <form onSubmit={handleAddItem}>
      <div className="form-group">
        <label>Pet ID</label>
        <input type="number" name="pet_id" min="1" required />
      </div>
      <div className="form-group">
        <label>Level</label>
        <input type="number" name="level" min="1" max="100" defaultValue="1" required />
      </div>
      <button type="submit" disabled={isLoading}>
        {isLoading ? 'Adding...' : 'Add Pet'}
      </button>
    </form>
  );

  return (
    <div className="item-manager-panel">
      <div className="data-item">
        <label>Category</label>
        <div className="category-selector">
          <button
            className={selectedCategory === 'currency' ? 'active' : ''}
            onClick={() => setSelectedCategory('currency')}
          >
            Currency
          </button>
          <button
            className={selectedCategory === 'rewards' ? 'active' : ''}
            onClick={() => setSelectedCategory('rewards')}
          >
            Rewards
          </button>
          <button
            className={selectedCategory === 'items' ? 'active' : ''}
            onClick={() => setSelectedCategory('items')}
          >
            Items
          </button>
          <button
            className={selectedCategory === 'pets' ? 'active' : ''}
            onClick={() => setSelectedCategory('pets')}
          >
            Pets
          </button>
        </div>
      </div>

      <div className="data-item">
        <label>{selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)}</label>
        <div className="form-container">
          {selectedCategory === 'currency' && renderCurrencyForm()}
          {selectedCategory === 'rewards' && renderRewardsForm()}
          {selectedCategory === 'items' && renderItemsForm()}
          {selectedCategory === 'pets' && renderPetsForm()}
        </div>
      </div>

      {statusMessage && (
        <div className={`status-message ${statusMessage.includes('Error') ? 'error' : 'success'}`}>
          {statusMessage}
        </div>
      )}
    </div>
  );
};

export default ItemManager;

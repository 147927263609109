import "../style/Payment.scss";
import Tokens from "./Tokens";
import emblemplus from "../img/emblemplus.png";
import emblem from "../img/emblem.png";
import bp from "../img/bp.png";
import rolls from "../img/rolls.png";
import token from "../img/token.png";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";

function Payment() {
  const accType = localStorage.getItem("acc_type");
  const username = localStorage.getItem("username");
  const accountId = localStorage.getItem("acc_id");
  const currentTokens = localStorage.getItem("tokens");
  const characters = JSON.parse(localStorage.getItem("characters") || "[]");
  const [selectedCharId, setSelectedCharId] = useState("");
  const [selectedSeason, setSelectedSeason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstAvailableCharId, setFirstAvailableCharId] = useState(null);
  const [activeTab, setActiveTab] = useState(accType === "Free User" || accType === "Premium User" ? "emblem" : "tokens");
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("paypal");
  const [showBenefitsModal, setShowBenefitsModal] = useState(false);
  const [benefitsType, setBenefitsType] = useState(""); // "emblem" or "emblemplus"

  const BenefitsModal = ({ onClose, type }) => {
    const benefits = type === "emblem" ? [
      "Can learn up to 3 Elements",
      "Free Scroll of Wisdom",
      "Exclusive Talent",
      "10 Tokens Daily",
      "Bonus XP (50 Battles Daily Limit)",
      "6 Character Slots",
      "+1 Event Stamina",
      "2 Daily Wishes",
      "2 Daily Spins",
      "3x Daily Party Control",
      "Exclusive Items",
      "Exclusive Skills",
      "Exclusive Daily Missions",
      "Massive Black Market Discounts",
      "Massive Skill Training Discounts",
      "Discounted Rename",
      "Discounted Reset"
    ] : [
      "Can learn all 5 elements",
      "Up to 50 Preset Slots",
      "Exclusive Emblem+ Set",
      "Bonus XP (100 Daily Battle Limit)",
      "2x Tokens",
      "Gear Filters",
      "+50 Max Stamina",
      "+30 Max Stamina",
      "+30 Ranked Stamina",
      "+2 Event Stamina",
      "Free Village Travel",
      "Unlimited Party Control",
      "Recruit Any Level",
      "Triple Gold",
      "Triple XP",
      "3 Wishes",
      "3 Spins",
      "2x Rewards",
      "Exclusive Items",
      "Exclusive Pets",
      "Exclusive Talent",
      "+ All Emblem Benefits"
    ];

    return (
      <div className="benefits-modal-overlay" onClick={onClose}>
        <div className="benefits-modal" onClick={e => e.stopPropagation()}>
          <div className="benefits-modal-header">
            <h3>{type === "emblem" ? "Emblem Benefits" : "Emblem+ Benefits"}</h3>
            <button className="close-button" onClick={onClose}>×</button>
          </div>
          <div className="benefits-modal-content">
            <div className="benefits-icon">
              <img src={type === "emblem" ? emblem : emblemplus} alt={type === "emblem" ? "Emblem" : "Emblem+"} />
            </div>
            <div className="benefits-list">
              {benefits.map((benefit, index) => (
                <div key={index} className="benefit-item">
                  <span className="benefit-bullet">•</span>
                  <span className="benefit-text">{benefit}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const currentSeason = characters[0]?.battle_pass_season || 13;
  const availableSeasons = Array.from(
    { length: currentSeason },
    (_, i) => i + 1
  );

  const toRoman = (num) => {
    const romanNumerals = {
      1: "I", 2: "II", 3: "III", 4: "IV", 5: "V",
      6: "VI", 7: "VII", 8: "VIII", 9: "IX", 10: "X",
      11: "XI", 12: "XII", 13: "XIII", 14: "XIV", 15: "XV",
      16: "XVI", 17: "XVII", 18: "XVIII", 19: "XIX", 20: "XX",
      21: "XXI", 22: "XXII", 23: "XXIII", 24: "XXIV", 25: "XXV",
      26: "XXVI", 27: "XXVII", 28: "XXVIII", 29: "XXIX", 30: "XXX",
      31: "XXXI", 32: "XXXII", 33: "XXXIII", 34: "XXXIV", 35: "XXXV",
      36: "XXXVI", 37: "XXXVII", 38: "XXXVIII", 39: "XXXIX", 40: "XL",
      41: "XLI", 42: "XLII", 43: "XLIII", 44: "XLIV", 45: "XLV",
      46: "XLVI", 47: "XLVII", 48: "XLVIII", 49: "XLIX", 50: "L",
      51: "LI", 52: "LII", 53: "LIII", 54: "LIV", 55: "LV",
      56: "LVI", 57: "LVII", 58: "LVIII", 59: "LIX", 60: "LX",
      61: "LXI", 62: "LXII", 63: "LXIII", 64: "LXIV", 65: "LXV",
      66: "LXVI", 67: "LXVII", 68: "LXVIII", 69: "LXIX", 70: "LXX",
      71: "LXXI", 72: "LXXII", 73: "LXXIII", 74: "LXXIV", 75: "LXXV",
      76: "LXXVI", 77: "LXXVII", 78: "LXXVIII", 79: "LXXIX", 80: "LXXX",
      81: "LXXXI", 82: "LXXXII", 83: "LXXXIII", 84: "LXXXIV", 85: "LXXXV",
      86: "LXXXVI", 87: "LXXXVII", 88: "LXXXVIII", 89: "LXXXIX", 90: "XC",
      91: "XCI", 92: "XCII", 93: "XCIII", 94: "XCIV", 95: "XCV",
      96: "XCVI", 97: "XCVII", 98: "XCVIII", 99: "XCIX", 100: "C"
    };
    return romanNumerals[num] || num;
  };

  const characterSlots = Array(6)
    .fill(null)
    .map((_, index) => ({
      slot: index + 1,
      charId: characters[index]?.char_id ?? null,
      charName: characters[index]?.character_name || null,
      charLevel: characters[index]?.character_level || null,
    }));

  useEffect(() => {
    const firstChar = characterSlots.find((slot) => slot.charId !== null);
    if (firstChar) {
      setFirstAvailableCharId(firstChar.charId);
    }
  }, [characterSlots]);

  useEffect(() => {
    if (selectedCharId) {
      const character = characters.find(
        (char) => char.char_id.toString() === selectedCharId.toString()
      );
      setSelectedCharacter(character);
      setSelectedSeason("");
    } else {
      setSelectedCharacter(null);
    }
  }, [selectedCharId]);

  const isSeasonOwned = (season) => {
    return selectedCharacter?.owned_battle_passes?.includes(season);
  };

  const handlePayPalCheckout = async (itemName, price, type, season = null) => {
    try {
      setIsLoading(true);

      const getPackageId = (type, price, season = null) => {
        switch (type) {
          case "emblem":
            return "package_7";
          case "emblemplus":
            return "package_8";
          case "emblemplus_upgrade":
            return "package_9";
          case "tokens":
            switch (price) {
              case "49.99": return "package_1";
              case "24.99": return "package_2";
              case "9.99": return "package_3";
              case "4.99": return "package_4";
              default: return null;
            }
          case "rolls":
            switch (price) {
              case "49.99": return "package_10_1";
              case "24.99": return "package_10_2";
              case "9.99": return "package_10_3";
              default: return null;
            }
          case "battlepass":
            const basePackageId = price === "14.99" ? "package_battle_pass_max" : "package_battle_pass_normal";
            return season ? `${basePackageId}_s${season}` : basePackageId;
          default:
            return null;
        }
      };

      if (!selectedCharId) {
        Swal.fire({
          icon: "warning",
          title: "Character Required",
          text: "Please select a character before making this purchase.",
        });
        setIsLoading(false);
        return;
      }

      if (type === "battlepass" && !selectedSeason) {
        Swal.fire({
          icon: "warning",
          title: "Season Required",
          text: "Please select a season before purchasing battle pass.",
        });
        setIsLoading(false);
        return;
      }

      const packageId = getPackageId(type, price, selectedSeason);
      if (!packageId) {
        throw new Error("Invalid package type or price");
      }

      const form = document.createElement("form");
      form.method = "post";
      form.action = "https://www.paypal.com/cgi-bin/webscr";

      const customData = {
        username: username,
        accountId: accountId,
        packageid: packageId,
        charid: selectedCharId,
        timestamp: new Date().toISOString(),
        season: selectedSeason,
      };

      const fields = {
        cmd: "_xclick",
        business: "ninjariftofficial@gmail.com",
        item_name: `${itemName} for ${username} (Character: ${selectedCharId})`,
        amount: price,
        currency_code: "USD",
        return: "https://playninjarift.com",
        cancel_return: "https://playninjarift.com",
        notify_url: "https://www.ninjarift.org/paypal/paypal_webhook.php",
        no_shipping: "1",
        no_note: "1",
        rm: "2",
        custom: JSON.stringify(customData),
        item_number: packageId,
        invoice: `${packageId}_${Date.now()}_${Math.random().toString(36).substr(2, 5)}`,
      };

      Object.entries(fields).forEach(([key, value]) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    } catch (error) {
      console.error("PayPal checkout error:", error);
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Checkout Failed",
        text: "There was an error processing your checkout. Please try again.",
      });
    }
  };

  const handleCheckout = (itemName, price, type, season = null) => {
    if (paymentMethod === "paypal") {
      handlePayPalCheckout(itemName, price, type, season);
    }
  };

  const showBenefits = (type) => {
    setBenefitsType(type);
    setShowBenefitsModal(true);
  };

  // Determine if we should show the emblem tab based on account type
  const showEmblemTab = accType === "Free User" || accType === "Premium User";

  return (
    <div className="payment-container">
      <div className="payment-method-selector">
        <div
          className={`payment-option ${paymentMethod === "paypal" ? "active" : ""}`}
          onClick={() => setPaymentMethod("paypal")}
        >
          <img src="https://www.paypalobjects.com/webstatic/icon/pp32.png" alt="PayPal" />
          <span>PayPal</span>
        </div>
        <div className={`payment-option disabled`}>
          <img src="https://public.bnbstatic.com/static/images/common/favicon.ico" alt="Binance Pay" />
          <span>Binance Pay</span>
          <div className="coming-soon">Coming Soon</div>
        </div>
      </div>

      <div className="duplicate-disclaimer">
        ⚠️ Note: If you already own any of the rewards in a package (emblem, items, etc.), you will not receive duplicate items. Only unique items will be added to your account. Emblem will not replace current Emblem or Emblem+.
      </div>

      <div className="character-selector">
        <label htmlFor="character-select">Select Character:</label>
        <select
          id="character-select"
          value={selectedCharId}
          onChange={(e) => setSelectedCharId(e.target.value)}
          className="character-dropdown"
        >
          <option value="">Select a character</option>
          {characterSlots.map((slot) =>
            slot.charId !== null ? (
              <option key={slot.slot} value={slot.charId}>
                {slot.charName} (Level {slot.charLevel})
              </option>
            ) : null
          )}
        </select>
      </div>

      <div className="purchase-tabs">
        <div className="tab-buttons">
          {showEmblemTab && (
            <button
              className={activeTab === "emblem" ? "active" : ""}
              onClick={() => setActiveTab("emblem")}
            >
              Emblem
            </button>
          )}
          <button
            className={activeTab === "tokens" ? "active" : ""}
            onClick={() => setActiveTab("tokens")}
          >
            Tokens
          </button>
          <button
            className={activeTab === "battlepass" ? "active" : ""}
            onClick={() => setActiveTab("battlepass")}
          >
            Battle Pass
          </button>
          <button
            className={activeTab === "rolls" ? "active" : ""}
            onClick={() => setActiveTab("rolls")}
          >
            Clan Rolls
          </button>
        </div>

        <div className="tab-content">

          {activeTab === "emblem" && (
            <div className="emblem-container">
              <div className="package-grid">
                {accType === "Free User" && (
                  <div className="package emblem-package">
                    <div className="package-info">
                      <h4>Emblem</h4>
                      <div className="package-icon">
                        <img src={emblem} alt="Emblem" />
                      </div>
                      <div className="rewards-list">
                        <div className="reward-item">Can learn up to 3 Elements</div>
                        <div className="reward-item">Free Scroll of Wisdom</div>
                        <div className="reward-item">Exclusive Talent</div>
                        <div className="reward-item">10 Tokens Daily</div>
                        <div className="reward-item">And many more benefits!</div>
                      </div>
                      <button 
                        className="view-benefits-button"
                        onClick={() => showBenefits("emblem")}
                      >
                        View All Benefits
                      </button>
                      <div className="price">$9.99</div>
                      <button
                        onClick={() => handleCheckout("Emblem", "9.99", "emblem")}
                        disabled={!selectedCharId || isLoading}
                      >
                        {isLoading ? "Processing..." : "Buy"}
                      </button>
                    </div>
                  </div>
                )}

                {(accType === "Free User" || accType === "Premium User") && (
                  <div className="package emblem-package">
                    <div className="package-info">
                      <h4>Emblem Plus</h4>
                      <div className="package-icon">
                        <img src={emblemplus} alt="Emblem Plus" />
                      </div>
                      <div className="rewards-list">
                        <div className="reward-item">Can learn all 5 elements</div>
                        <div className="reward-item">Up to 50 Preset Slots</div>
                        <div className="reward-item">Exclusive Emblem+ Set</div>
                        <div className="reward-item">And many more benefits!</div>
                      </div>
                      <button 
                        className="view-benefits-button"
                        onClick={() => showBenefits("emblemplus")}
                      >
                        View All Benefits
                      </button>
                      <div className="price">
                        {accType === "Premium User" ? "$9.99" : "$19.99"}
                      </div>
                      <button
                        onClick={() =>
                          handleCheckout(
                            "Emblem Plus",
                            accType === "Premium User" ? "9.99" : "19.99",
                            accType === "Premium User" ? "emblemplus_upgrade" : "emblemplus"
                          )
                        }
                        disabled={!selectedCharId || isLoading}
                      >
                        {isLoading ? "Processing..." : accType === "Premium User" ? "Upgrade" : "Buy"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {activeTab === "tokens" && (
            <div className="tokens-container">
              <div className="package-grid">
                <div className="package token-package">
                  <div className="package-info">
                    <h4>30,000 Tokens</h4>
                    <div className="package-icon">
                      <img src={token} alt="30,000 Tokens" />
                    </div>
                    <div className="price">$49.99</div>
                    <button
                      onClick={() => handleCheckout("30,000 Tokens", "49.99", "tokens")}
                      disabled={!selectedCharId || isLoading}
                    >
                      {isLoading ? "Processing..." : "Buy"}
                    </button>
                  </div>
                </div>
                <div className="package token-package">
                  <div className="package-info">
                    <h4>13,500 Tokens</h4>
                    <div className="package-icon">
                      <img src={token} alt="13,500 Tokens" />
                    </div>
                    <div className="price">$24.99</div>
                    <button
                      onClick={() => handleCheckout("13,500 Tokens", "24.99", "tokens")}
                      disabled={!selectedCharId || isLoading}
                    >
                      {isLoading ? "Processing..." : "Buy"}
                    </button>
                  </div>
                </div>
                <div className="package token-package">
                  <div className="package-info">
                    <h4>5,000 Tokens</h4>
                    <div className="package-icon">
                      <img src={token} alt="5,000 Tokens" />
                    </div>
                    <div className="price">$9.99</div>
                    <button
                      onClick={() => handleCheckout("5,000 Tokens", "9.99", "tokens")}
                      disabled={!selectedCharId || isLoading}
                    >
                      {isLoading ? "Processing..." : "Buy"}
                    </button>
                  </div>
                </div>
                <div className="package token-package">
                  <div className="package-info">
                    <h4>2,200 Tokens</h4>
                    <div className="package-icon">
                      <img src={token} alt="2,200 Tokens" />
                    </div>
                    <div className="price">$4.99</div>
                    <button
                      onClick={() => handleCheckout("2,200 Tokens", "4.99", "tokens")}
                      disabled={!selectedCharId || isLoading}
                    >
                      {isLoading ? "Processing..." : "Buy"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === "battlepass" && (
            <div className="battlepass-container">
              {selectedCharId && (
                <div className="season-selector">
                  <label htmlFor="season-select">Select Season:</label>
                  <select
                    id="season-select"
                    value={selectedSeason}
                    onChange={(e) => setSelectedSeason(e.target.value)}
                    className="season-dropdown"
                  >
                    <option value="">Select a Season</option>
                    {availableSeasons.map((season) => {
                      const owned = isSeasonOwned(season);
                      return (
                        <option key={season} value={season} disabled={owned}>
                          Season {season} {owned ? "(Owned)" : ""}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <div className="package-grid">
                <div className="package battlepass-package">
                  <div className="package-info">
                    <h4>Battle Pass</h4>
                    <p>Start from Level 1</p>
                    <div className="package-icon">
                      <img src={bp} alt="Battle Pass" />
                      {selectedSeason && (
                        <span className="season-number">
                          {toRoman(parseInt(selectedSeason))}
                        </span>
                      )}
                    </div>
                    <div className="price">$9.99</div>
                    <button
                      onClick={() =>
                        handleCheckout(
                          "Battle Pass",
                          "9.99",
                          "battlepass",
                          selectedSeason
                        )
                      }
                      disabled={
                        !selectedCharId ||
                        !selectedSeason ||
                        isLoading ||
                        isSeasonOwned(parseInt(selectedSeason))
                      }
                    >
                      {isLoading
                        ? "Processing..."
                        : isSeasonOwned(parseInt(selectedSeason))
                        ? "Owned"
                        : "Buy"}
                    </button>
                  </div>
                </div>
                <div className="package battlepass-package">
                  <div className="package-info">
                    <h4>Battle Pass Max Level</h4>
                    <p>Instantly unlock max level</p>
                    <div className="package-icon">
                      <img src={bp} alt="Battle Pass Max" />
                      {selectedSeason && (
                        <span className="season-number">
                          {toRoman(parseInt(selectedSeason))}
                        </span>
                      )}
                    </div>
                    <div className="price">$14.99</div>
                    <button
                      onClick={() =>
                        handleCheckout(
                          "Battle Pass Max Level",
                          "14.99",
                          "battlepass",
                          selectedSeason
                        )
                      }
                      disabled={
                        !selectedCharId ||
                        !selectedSeason ||
                        isLoading ||
                        isSeasonOwned(parseInt(selectedSeason))
                      }
                    >
                      {isLoading
                        ? "Processing..."
                        : isSeasonOwned(parseInt(selectedSeason))
                        ? "Owned"
                        : "Buy"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === "rolls" && (
            <div className="rolls-container">
              <div className="package-grid">
                <div className="package roll-package">
                  <div className="package-info">
                    <h4>3,400 Rolls</h4>
                    <div className="package-icon">
                      <img src={rolls} alt="3400 Rolls" />
                    </div>
                    <div className="price">$49.99</div>
                    <button
                      onClick={() =>
                        handleCheckout("3400 Clan Rolls", "49.99", "rolls")
                      }
                      disabled={!selectedCharId || isLoading}
                    >
                      {isLoading ? "Processing..." : "Buy"}
                    </button>
                  </div>
                </div>
                <div className="package roll-package">
                  <div className="package-info">
                    <h4>1,600 Rolls</h4>
                    <div className="package-icon">
                      <img src={rolls} alt="1600 Rolls" />
                    </div>
                    <div className="price">$24.99</div>
                    <button
                      onClick={() =>
                        handleCheckout("1600 Clan Rolls", "24.99", "rolls")
                      }
                      disabled={!selectedCharId || isLoading}
                    >
                      {isLoading ? "Processing..." : "Buy"}
                    </button>
                  </div>
                </div>
                <div className="package roll-package">
                  <div className="package-info">
                    <h4>600 Rolls</h4>
                    <div className="package-icon">
                      <img src={rolls} alt="600 Rolls" />
                    </div>
                    <div className="price">$9.99</div>
                    <button
                      onClick={() =>
                        handleCheckout("600 Clan Rolls", "9.99", "rolls")
                      }
                      disabled={!selectedCharId || isLoading}
                    >
                      {isLoading ? "Processing..." : "Buy"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showBenefitsModal && (
        <BenefitsModal
          onClose={() => setShowBenefitsModal(false)}
          type={benefitsType}
        />
      )}
    </div>
  );
}

export default Payment;

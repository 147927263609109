import React, { useState, useEffect } from 'react';

const Endpoint = ({ subcategoryId }) => {
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const contentResponse = await fetch(
          `https://playninjarift.com/server/admin/get_endpoint_content.php?subcategory_id=${subcategoryId}`
        );
        const contentResult = await contentResponse.json();

        if (contentResult.status === 'success' && contentResult.data && contentResult.data.length > 0) {
          const processedContent = processContent(contentResult.data[0].content);
          setContent({
            ...contentResult.data[0],
            content: processedContent
          });
          setError(null);
        } else {
          throw new Error(contentResult.message || 'No content available');
        }
      } catch (error) {
        console.error('Error fetching content:', error);
        setError(error.message || 'Failed to load content');
      } finally {
        setIsLoading(false);
      }
    };

    if (subcategoryId) {
      fetchData();
    }
  }, [subcategoryId]);

  const processContent = (htmlContent) => {
    if (!htmlContent) return '';

    const div = document.createElement('div');
    div.innerHTML = htmlContent;

    // Process all paragraphs containing images
    const paragraphs = div.querySelectorAll('p');
    paragraphs.forEach(p => {
      const images = p.querySelectorAll('img');
      if (images.length > 0) {
        // Clear the paragraph content and add the grid class
        p.className = 'image-row';
        p.style.display = 'flex';
        p.style.flexWrap = 'wrap';
        p.style.gap = '8px';
        
        // Process each image
        images.forEach(img => {
          img.setAttribute('loading', 'lazy');
          img.setAttribute('draggable', 'false');
          img.style.width = 'calc(20% - 7px)'; // 5 images per row with gap
          img.style.height = '120px';
          img.style.objectFit = 'cover';
          img.style.margin = '0';
        });
      }
    });

    return div.innerHTML;
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Loading content...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-400">
        {error}
      </div>
    );
  }

  if (!content) {
    return (
      <div className="text-center py-8 text-gray-400">
        No content available
      </div>
    );
  }

  return (
    <article className="endpoint-wrapper">
      <div className="endpoint-content">
        <div className="mb-6">
          <h1 className="endpoint-title text-2xl font-bold text-white mb-2">
            {content.title}
          </h1>
          {content.subtitle && (
            <p className="text-gray-400">{content.subtitle}</p>
          )}
        </div>
        <div
          className="endpoint-body prose prose-invert max-w-none"
          dangerouslySetInnerHTML={{ __html: content.content }}
        />
      </div>
    </article>
  );
};

export default Endpoint;
import React, { useState, useEffect } from 'react';
import MaintenanceScheduler from './MaintenanceScheduler';
import ClanSeasonManager from './ClanSeasonManager';
import CrewSeasonManager from './CrewSeasonManager';
import StatusMessage from './StatusMessage';
import './ServerSettings.scss';

const ServerSettings = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [scheduledMaintenance, setScheduledMaintenance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [serverTime, setServerTime] = useState('');
  const [clanSeasonInfo, setClanSeasonInfo] = useState({
    currentSeason: 0,
    isActive: false,
    endDate: '',
    resultsSaved: false
  });
  const [crewSeasonInfo, setCrewSeasonInfo] = useState({
    currentSeason: 0,
    isActive: false,
    endDate: '',
    resultsSaved: false,
    phase: 1,
    phase2EndDate: null
  });
  const [crewRewards, setCrewRewards] = useState({
    phase1Reward: '',
    phase2Rewards: ['', '', '', '', '', '', '']
  });

  useEffect(() => {
    const updateServerTime = () => {
      const now = new Date();
      const chicagoTime = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Chicago',
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }).format(now);
      setServerTime(chicagoTime);
    };

    updateServerTime();
    const interval = setInterval(updateServerTime, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchServerStatus();
    const refreshInterval = setInterval(fetchServerStatus, 60000);
    return () => clearInterval(refreshInterval);
  }, []);

  const formatDateTime = (dateStr) => {
    if (!dateStr) return '';
    return new Date(dateStr).toLocaleString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const getTimeFromNow = (dateStr) => {
    const targetDate = new Date(dateStr);
    const now = new Date();
    const chicagoOffset = -6;
    const localOffset = now.getTimezoneOffset() / 60;
    const offsetDiff = localOffset + chicagoOffset;
    const adjustedNow = new Date(now.getTime() + (offsetDiff * 60 * 60 * 1000));
    const diffTime = targetDate - adjustedNow;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

    if (diffDays > 0) return `${diffDays}d ${diffHours}h from now`;
    if (diffHours > 0) return `${diffHours}h ${diffMinutes}m from now`;
    if (diffMinutes > 0) return `${diffMinutes} minutes from now`;
    return 'Starting now';
  };

  const getDuration = (startStr, endStr) => {
    const start = new Date(startStr);
    const end = new Date(endStr);
    const diffTime = end - start;
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    return diffHours > 0 ? `${diffHours}h ${diffMinutes}m` : `${diffMinutes} minutes`;
  };

  const fetchServerStatus = async () => {
    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const formData = new URLSearchParams();
      formData.append('action', 'get_maintenance_status');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setMaintenanceMode(data.maintenance);
        // Get the next scheduled or in-progress maintenance
        setScheduledMaintenance(data.scheduled_maintenances?.[0] || null);
        setClanSeasonInfo({
          currentSeason: data.clan_season,
          isActive: data.clan_season_active,
          endDate: data.clan_season_end,
          resultsSaved: data.results_saved
        });
        setCrewSeasonInfo({
          currentSeason: data.crew_season,
          isActive: data.crew_season_active,
          endDate: data.crew_season_end,
          resultsSaved: data.crew_results_saved,
          phase: data.crew_phase,
          phase2EndDate: data.crew_phase2_end_date
        });
        setStatusMessage('');
      } else {
        setStatusMessage(data.error || 'Error fetching server status');
      }
    } catch (err) {
      setStatusMessage('Error fetching server status');
    }
  };

  const handleScheduleMaintenance = async ({ startTime, endTime }) => {
    setIsLoading(true);
    setStatusMessage('');

    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const formData = new URLSearchParams();
      formData.append('action', 'schedule_maintenance');
      formData.append('start_time', startTime);
      formData.append('end_time', endTime);

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error scheduling maintenance');
      }
    } catch (err) {
      setStatusMessage('Error scheduling maintenance');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelMaintenance = async () => {
    if (!scheduledMaintenance || scheduledMaintenance.status !== 'scheduled') return;
    
    setIsLoading(true);
    setStatusMessage('');

    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const formData = new URLSearchParams();
      formData.append('action', 'cancel_scheduled_maintenance');
      formData.append('schedule_id', scheduledMaintenance.id);

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error cancelling maintenance');
      }
    } catch (err) {
      setStatusMessage('Error cancelling maintenance');
    } finally {
      setIsLoading(false);
    }
  };

  const handleMaintenanceToggle = async () => {
    setIsLoading(true);
    setStatusMessage('');

    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const action = maintenanceMode ? 'disable_maintenance' : 'enable_maintenance';
      
      const formData = new URLSearchParams();
      formData.append('action', action);

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setMaintenanceMode(!maintenanceMode);
        setStatusMessage(data.message);
      } else {
        setStatusMessage(data.error || 'Error toggling maintenance mode');
      }
    } catch (err) {
      setStatusMessage('Error toggling maintenance mode');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEndClanSeason = async () => {
    setIsLoading(true);
    setStatusMessage('');

    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const formData = new URLSearchParams();
      formData.append('action', 'end_clan_season');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error ending clan season');
      }
    } catch (err) {
      setStatusMessage('Error ending clan season');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveClanResults = async () => {
    setIsLoading(true);
    setStatusMessage('');

    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const formData = new URLSearchParams();
      formData.append('action', 'save_clan_season_results');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error saving season results');
      }
    } catch (err) {
      setStatusMessage('Error saving season results');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartNewClanSeason = async () => {
    setIsLoading(true);
    setStatusMessage('');

    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const formData = new URLSearchParams();
      formData.append('action', 'start_new_clan_season');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error starting new clan season');
      }
    } catch (err) {
      setStatusMessage('Error starting new clan season');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEndCrewSeason = async () => {
    setIsLoading(true);
    setStatusMessage('');

    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const formData = new URLSearchParams();
      formData.append('action', 'end_crew_season');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error ending crew season');
      }
    } catch (err) {
      setStatusMessage('Error ending crew season');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveCrewResults = async () => {
    setIsLoading(true);
    setStatusMessage('');

    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const formData = new URLSearchParams();
      formData.append('action', 'save_crew_season_results');

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
      } else {
        setStatusMessage(data.error || 'Error saving crew season results');
      }
    } catch (err) {
      setStatusMessage('Error saving crew season results');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartNewCrewSeason = async () => {
    if (!crewRewards.phase1Reward) {
      setStatusMessage('Phase 1 reward is required');
      return;
    }
    if (crewRewards.phase2Rewards.some(reward => !reward)) {
      setStatusMessage('All Phase 2 rewards are required');
      return;
    }

    setIsLoading(true);
    setStatusMessage('');

    try {
      const csrf_token = document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1];
      if (!csrf_token) return;

      const formData = new URLSearchParams();
      formData.append('action', 'start_new_crew_season');
      formData.append('phase1_reward', crewRewards.phase1Reward);
      formData.append('phase2_rewards', JSON.stringify(crewRewards.phase2Rewards));

      const response = await fetch('https://ninjarift.org/adminpanel/dashboard.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrf_token
        },
        body: formData
      });

      const data = await response.json();
      if (data.success) {
        setStatusMessage(data.message);
        fetchServerStatus();
        setCrewRewards({
          phase1Reward: '',
          phase2Rewards: ['', '', '', '', '', '', '']
        });
      } else {
        setStatusMessage(data.error || 'Error starting new crew season');
      }
    } catch (err) {
      setStatusMessage('Error starting new crew season');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhase1RewardChange = (value) => {
    setCrewRewards(prev => ({
      ...prev,
      phase1Reward: value
    }));
  };

  const handlePhase2RewardChange = (index, value) => {
    setCrewRewards(prev => ({
      ...prev,
      phase2Rewards: prev.phase2Rewards.map((reward, i) => i === index ? value : reward)
    }));
  };

  return (
    <div className="server-settings-panel">
      <div className="settings-section">
        <h3>Server Time</h3>
        <div className="content-wrapper">
          <div className="server-time">{serverTime}</div>
        </div>
      </div>

      <MaintenanceScheduler
        maintenanceMode={maintenanceMode}
        maintenanceSchedule={scheduledMaintenance}
        onScheduleMaintenance={handleScheduleMaintenance}
        onCancelMaintenance={handleCancelMaintenance}
        onMaintenanceToggle={handleMaintenanceToggle}
        formatDateTime={formatDateTime}
        getDuration={getDuration}
        getTimeFromNow={getTimeFromNow}
        isLoading={isLoading}
      />

      <ClanSeasonManager
        clanSeasonInfo={clanSeasonInfo}
        isLoading={isLoading}
        onEndSeason={handleEndClanSeason}
        onSaveResults={handleSaveClanResults}
        onStartNewSeason={handleStartNewClanSeason}
        formatDateTime={formatDateTime}
      />

      <CrewSeasonManager
        crewSeasonInfo={crewSeasonInfo}
        crewRewards={crewRewards}
        isLoading={isLoading}
        onEndSeason={handleEndCrewSeason}
        onSaveResults={handleSaveCrewResults}
        onStartNewSeason={handleStartNewCrewSeason}
        onPhase1RewardChange={handlePhase1RewardChange}
        onPhase2RewardChange={handlePhase2RewardChange}
        formatDateTime={formatDateTime}
      />

      <StatusMessage message={statusMessage} />
    </div>
  );
};

export default ServerSettings;

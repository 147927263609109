import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Lock, User } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import './AdminLogin.scss';

const AdminLogin = () => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });
  const [verificationCode, setVerificationCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [needsVerification, setNeedsVerification] = useState(false);
  const [accountData, setAccountData] = useState(null);
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    // Check if we already have a valid session
    const checkSession = async () => {
      try {
        const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRF-Token': document.cookie.split('; ').find(row => row.startsWith('csrf_token='))?.split('=')[1] || ''
          },
          body: 'check=1'
        });

        const data = await response.json();
        if (data.valid) {
          await login();
          navigate('/admin/AdminPanel');
        }
      } catch (err) {
        console.error('Session check error:', err);
      }
    };

    checkSession();
  }, [navigate, login]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `mail=${encodeURIComponent(credentials.username)}&password=${encodeURIComponent(credentials.password)}`
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
        return;
      }

      if (data.needs_verification) {
        setNeedsVerification(true);
        setAccountData({
          acc_id: data.acc_id,
          username: data.username,
          csrf_token: data.csrf_token
        });
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to connect to server');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await fetch('https://ninjarift.org/adminpanel/login.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-TOKEN': accountData.csrf_token
        },
        body: `code=${encodeURIComponent(verificationCode)}&acc_id=${encodeURIComponent(accountData.acc_id)}`
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
        return;
      }

      if (data.verified) {
        await login();
        navigate('/admin/AdminPanel');
      } else {
        setError('Verification failed');
      }
    } catch (err) {
      console.error('Verification error:', err);
      setError('Failed to verify code');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="admin-login-container">
      <div className="login-card">
        <div className="login-header">
          <h1>Wiki Admin Login</h1>
          <p>Enter your credentials to access the wiki admin panel</p>
        </div>

        {error && <div className="error-message">{error}</div>}

        {!needsVerification ? (
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label htmlFor="username">
                <User className="icon" />
                Email
              </label>
              <input
                type="email"
                id="username"
                name="username"
                value={credentials.username}
                onChange={handleChange}
                placeholder="Enter your email"
                required
                disabled={isLoading}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">
                <Lock className="icon" />
                Password
              </label>
              <div className="password-input">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={credentials.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  required
                  disabled={isLoading}
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={() => setShowPassword(!showPassword)}
                  disabled={isLoading}
                >
                  {showPassword ? <EyeOff className="icon" /> : <Eye className="icon" />}
                </button>
              </div>
            </div>

            <button 
              type="submit" 
              className={`login-button ${isLoading ? 'loading' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Logging in...' : 'Login to Admin Panel'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleVerification} className="login-form">
            <div className="verification-message">
              A verification code has been sent to your email.
            </div>
            <div className="form-group">
              <label htmlFor="verificationCode">Verification Code</label>
              <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Enter 6-digit code"
                maxLength="6"
                required
                disabled={isLoading}
              />
            </div>
            <button 
              type="submit" 
              className={`login-button ${isLoading ? 'loading' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Verifying...' : 'Verify Code'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
